
export enum FormMode {
    EMPLOYEE = 'calculator',
    EMPLOYEE_AT = 'calculatorat',
    OFFICIAL = 'official',
    SELF_EMPLOYED = 'selfemployed',
    SELF_EMPLOYED_AT = 'selfemployedat',
    COLLECTIVE_AGREEMENT = 'collectiveagreement'
}

export enum VALID_COUNTRIES {
    GERMANY = 'de',
    AUSTRIA = "at",
}

export enum VALID_LOCALES_EXTENTION {
    USA = 'US',
    GB = 'GB',
}


export enum ValidLocales {
    GERMANY = 'de-DE',
    AUSTRIA = 'de-AT',
    USA = 'en-US',
}


export enum StandardColors {
    PRIMARY = '#91c434',
    SECONDARY = '#000',
}

export enum FormType {
    EMPLOYEE = 'employee',
    SELF_EMPLOYED = 'selfEmployed',
    OFFICIAL = 'official',
}

export enum Insurance {
    LEGAL = 'legal',
    PRIVATE = 'private',
    NONE = '-'
}

export enum CalculationMethod {
    GROSS_SALARY_DEDUCTION = 'gross_salary_deduction',
    TAX_REDUCTION = 'tax_reduction',
    NONE = '-',
}