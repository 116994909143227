<template>
  <router-view />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import emitter from "@/services/emitter";
import { mapFormLimitsValues } from './mapper/form-limits-mapper';

export default defineComponent({
  name: 'App',
	// beforeCreate() {
	// 	this.$store.commit('initialiseStore');
	// },
  created() {
    emitter.on("refreshStore", () => {
      this.$store.state.appData.urlParameter = this.$route.query;
      mapFormLimitsValues(this.$store.state);
    });
  },
});
</script>

<style lang="scss">
@import "@/styles/main.scss";
</style>