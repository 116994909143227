// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/fonts/icomoon.eot?agt8h8", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("@/fonts/icomoon.ttf?agt8h8", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("@/fonts/icomoon.woff?agt8h8", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("@/fonts/icomoon.svg?agt8h8", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___, { hash: "#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___, { hash: "#icomoon" });
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-family: \"icomoon\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"embedded-opentype\"), url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"truetype\"), url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"woff\"), url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"svg\");\n  font-weight: normal;\n  font-style: normal;\n  font-display: block;\n}\n[class*=icon-]:before,\n.icon {\n  /* use !important to prevent issues with browser extensions that change fonts */\n  font-family: \"icomoon\" !important;\n  font-style: normal;\n  font-weight: normal;\n  font-variant: normal;\n  text-transform: none;\n  line-height: 1;\n  /* Better Font Rendering =========== */\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n.icon-user:before {\n  content: \"\\e971\";\n}\n.icon-file:before {\n  content: \"\\e901\";\n}\n.icon-office:before {\n  content: \"\\e903\";\n}\n.icon-calculator:before {\n  content: \"\\e940\";\n}\n.icon-file-pdf-solid:before {\n  content: \"\\eadf\";\n}\n.icon-bin:before {\n  content: \"\\e9ac\";\n}\n.icon-check-alt:before {\n  content: \"\\e900\";\n}\n.icon-pencil:before {\n  content: \"\\e905\";\n}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
