import { vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = ["id", "name"]
const _hoisted_3 = ["for", "title"]
const _hoisted_4 = ["for", "title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("fieldset", {
    class: _normalizeClass('form-check form-switch form-switch-simple field-is-' + _ctx.type),
    disabled: _ctx.isDisabled(_ctx.fieldId)
  }, [
    _withDirectives(_createElementVNode("input", {
      id: _ctx.fieldId,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
      type: "checkbox",
      class: "form-check-input",
      name: _ctx.fieldId
    }, null, 8 /* PROPS */, _hoisted_2), [
      [_vModelCheckbox, _ctx.value]
    ]),
    (_ctx.value)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: _ctx.fieldId,
          title: _ctx.statusOn,
          class: "simple-form-check-label"
        }, " " + _toDisplayString(_ctx.statusOn), 9 /* TEXT, PROPS */, _hoisted_3))
      : (_openBlock(), _createElementBlock("label", {
          key: 1,
          for: _ctx.fieldId,
          title: _ctx.statusOff,
          class: "simple-form-check-label"
        }, " " + _toDisplayString(_ctx.statusOff), 9 /* TEXT, PROPS */, _hoisted_4))
  ], 10 /* CLASS, PROPS */, _hoisted_1))
}