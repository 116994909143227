import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "nav nav-pills nav-justified" }
const _hoisted_2 = {
  key: 0,
  class: "nav-item active"
}
const _hoisted_3 = {
  key: 1,
  class: "nav-item"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconComponent = _resolveComponent("IconComponent")!
  const _component_ButtonComponent = _resolveComponent("ButtonComponent")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.steps, (step, index) => {
      return (_openBlock(), _createElementBlock(_Fragment, {
        key: step.id
      }, [
        (_ctx.activeStep == parseInt(index))
          ? (_openBlock(), _createElementBlock("li", _hoisted_2, [
              _createVNode(_component_ButtonComponent, {
                class: "nav-link active secondary border-bottom border-primary py-4",
                "aria-current": "page",
                text: step.headline[_ctx.$i18n.locale]
              }, {
                before: _withCtx(() => [
                  _createVNode(_component_IconComponent, {
                    icon: step.icon,
                    size: "4 d-block mb-2"
                  }, null, 8 /* PROPS */, ["icon"])
                ]),
                _: 2 /* DYNAMIC */
              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["text"])
            ]))
          : (_openBlock(), _createElementBlock("li", _hoisted_3, [
              _createVNode(_component_ButtonComponent, {
                class: "nav-link border-bottom py-4",
                text: step.headline[_ctx.$i18n.locale],
                onClick: ($event: any) => (_ctx.changeStepTo(parseInt(index)))
              }, {
                before: _withCtx(() => [
                  _createVNode(_component_IconComponent, {
                    icon: step.icon,
                    size: "4 d-block mb-2"
                  }, null, 8 /* PROPS */, ["icon"])
                ]),
                _: 2 /* DYNAMIC */
              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["text", "onClick"])
            ]))
      ], 64 /* STABLE_FRAGMENT */))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}