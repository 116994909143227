import { FormMode, VALID_COUNTRIES, ValidLocales } from "@/enums/Application";
import { mapFormDefaultValues } from "@/mapper/form-defaults-mapper";
import { IEmployer } from "@/models/IEmployer";
import { InjectionKey } from "vue";
import { createStore, Store, useStore as baseUseStore } from "vuex";
import { defaultEmployer, defaultForm, defaultValues } from "./defaults";
import { IFormValues } from "./interfaces/IFormValues";
import { Color } from "@/interfaces/colors";
import { hexToRgb } from "@/helper/coloring-helper";
import { Api } from "@/services/api-service";
import { mapFormLimitsValues } from "@/mapper/form-limits-mapper";
import {AxiosResponse} from "axios";

export interface ComponentState {
  disabled?: boolean;
}

export interface SliderDefaults extends ComponentState {
  minValue?: number;
  maxValue?: number;
}

export interface AppData {
  formType: FormMode;
  code: string;
  company: string;
  showModal: boolean;
  urlParameter: Map<string, string>;
  lang: string;
  country: string;
}

export interface State {
  employer: IEmployer;
  formValues: IFormValues;
  formDefaults: Map<string, SliderDefaults>;
  appData: AppData;
}

export interface FormPayload extends Record<string, string | number | boolean> {
  key: string;
  value: string | number | boolean;
}

export const key: InjectionKey<Store<State>> = Symbol();

// TODO: Work with Vuex Modules and Namespaces to bring the state into a better structure
export const store = createStore<State>({
  state: {
    formValues: defaultForm,
    formDefaults: defaultValues,
    employer: defaultEmployer,    
    appData: {
      formType: FormMode.EMPLOYEE,
      code: '',
      company: '',
      showModal: false,
      urlParameter: new Map<string, string>(),
      lang: 'de-DE' as ValidLocales,
      country: VALID_COUNTRIES.GERMANY,
    }
  },
  getters: {
    getEmployer(state) {
        return state.employer;
    },
    getFormValues(state) {
        return state.formValues;
    },
    getPrimaryColor(state): Color {
      const rgbColor = hexToRgb(state.employer.primaryColor);
      return {
        hex: state.employer.primaryColor,
        rgb: rgbColor,
      }
    },
    getSecondaryColor(state): Color {
      const rgbColor = hexToRgb(state.employer.secondaryColor);
      return {
        hex: state.employer.secondaryColor,
        rgb: rgbColor,
      };
    }
  },
  mutations: {
    setEmployer(state, employer: IEmployer) {
      state.employer = employer;
    },
    resetEmployer(state) {
      state.employer = defaultEmployer;
      state.formValues = defaultForm;     
      state.formDefaults = defaultValues;
      mapFormDefaultValues(state);
    },
    setFormValuesAndLimits(state) {
      mapFormDefaultValues(state);
      mapFormLimitsValues(state);
    },
    setAppDataCode(state, code: string) {
      state.appData.code = code;
    },
    setAppDataCompany(state, company: string) {
      state.appData.company = company;
    },
    setEmployerCompany(state, company) {
      state.employer.company = company;
    },
    setFormValue(state, payload: FormPayload) {
      state.formValues = Object.assign(state.formValues, {[payload.key]: payload.value});      
    },
    resetForm(state) {
      state.employer = defaultEmployer;
      state.formValues = defaultForm;
      state.formDefaults = defaultValues;
      mapFormDefaultValues(state);
    },
    setFormType(state, formType: FormMode) {
      state.appData.formType = formType;
    },
    setCompany(state, company: string) {
      state.appData.company = company;
    },
    setCode(state, code: string) {
      state.appData.code = code;
    },
    setForm(state, form: FormMode) {
      state.appData.formType = form;
    },
    setShowModal(state, modalState: boolean) {
      state.appData.showModal = modalState;
    },
    resetAppData(state) {
      state.appData.company = '';
      state.appData.code = '';
    },
    setLang(state, lang: string) {
      state.appData.lang = lang;
    },
    setCountry(state, country: string) {
      state.appData.country = country.toUpperCase();
    },
    setPrimaryColor(state, color: string) {
      state.employer.primaryColor = color;
    },
    setSecondaryColor(state, color: string) {
      state.employer.secondaryColor = color;
    },
  },
  actions: {
    setEmployer({ commit }, employer: IEmployer) {

      if(employer && employer.companyCode !== undefined) {
        commit('setAppDataCode', employer.companyCode);
        commit('setAppDataCompany', employer.company);
      }

      commit("setEmployer", employer);
    },
    resetEmployer({ commit }) {
      commit("setAppDataCompany", '---');
      commit("setAppDataCode", '');
      commit("resetEmployer");
    },
    setEmployerColors({ commit }, colorSettings: Color[]) {
      commit('setPrimaryColor', colorSettings[0].hex);
      commit('setSecondaryColor', colorSettings[1].hex);
    },
    async setEmployerByCompanyCode({ commit }, companyCode: string) : Promise<boolean> {
      const response:AxiosResponse<IEmployer> = await Api.getCompanyByCode(companyCode);
      if (response === null) {
        return false;
      }
      await commit("setEmployer", response.data);
      await commit("setCountry", response.data.country ?? VALID_COUNTRIES.GERMANY);
      await commit("setFormValuesAndLimits");
      return true;
    }
  },

})


export function useStore () {
  return baseUseStore(key);
}

// Subscribe to store updates
store.subscribe((mutation, state) => {
	// Store the state object as a JSON string
	localStorage.setItem('store', JSON.stringify({
    employer: state.employer,
    formValues: state.formValues,
    formDefaults: Array.from(state.formDefaults),
    appData: state.appData,
  }));
});