<template>
  <div
    :class="'modal fade ' + activeClass"
    tabindex="-1"
    ariaHidden="ariaHidden"
    :style="'display:' + displayModal"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ modalTitle }}
          </h5>
        </div>
        <div class="modal-body">
          <slot name="body" />
        </div>
        <div class="modal-footer justify-content-start">
          <slot name="footer" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
	name: 'ModalComponent',
	components: {
	},
	props: {
		modalTitle: { type: String, default: 'modalTitle' },
		showModal: { type: Boolean }
	},
	data() {
		return {
				ariaHidden: "false",
				displayModal: "block",
				activeClass: "show"
		}
	},
	watch: {
		showModal() {
			if(this.showModal) {
				this.ariaHidden = "false"
				this.displayModal = "block"
				this.activeClass = "show"
			} else {
				this.ariaHidden = "true"
				this.displayModal = "none"
				this.activeClass = "hide"
			}
		}
	},
})
</script>

<style lang="scss">
@import "@/styles/elements/modal.scss";
</style>