import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "modal-dialog" }
const _hoisted_2 = { class: "modal-content" }
const _hoisted_3 = { class: "modal-header" }
const _hoisted_4 = { class: "modal-title" }
const _hoisted_5 = { class: "modal-body" }
const _hoisted_6 = { class: "modal-footer justify-content-start" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass('modal fade ' + _ctx.activeClass),
    tabindex: "-1",
    ariaHidden: "ariaHidden",
    style: _normalizeStyle('display:' + _ctx.displayModal)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("h5", _hoisted_4, _toDisplayString(_ctx.modalTitle), 1 /* TEXT */)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _renderSlot(_ctx.$slots, "body")
        ]),
        _createElementVNode("div", _hoisted_6, [
          _renderSlot(_ctx.$slots, "footer")
        ])
      ])
    ])
  ], 6 /* CLASS, STYLE */))
}