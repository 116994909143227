<template>
  <div class="form-floating">
    <input
      :id="'input-'+id"
      v-model="value"
      class="form-control"
      type="text"
      :name="'input-'+id"
      :placeholder="placeholder[$i18n.locale]"
      @change="change()"
    >
    <LabelComponent
      v-if="label"
      :label="label.toString()"
      :label-for="'input-'+id"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import LabelComponent from '@/components/form/LabelComponent.vue'

export default defineComponent({
  name: 'InputComponent',
  components: {
    LabelComponent
  },
  props: {
    label: { type: Object, required: true },
    placeholder: { type: Object, required: true }
  },
  emits: ['change'],
  data() {
    return {
      id: Math.ceil(Math.random()*1000000),
      value: ""
    }
  },
  methods: {
    change() {
      this.$emit('change', this.value, `input${this.id}`);      
    }
  }
})
</script>

<style lang="scss">
@import "@/styles/elements/form/_all.scss";
</style>