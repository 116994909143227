<template>
  <fieldset :class="'row mt-3 field-is-' + type" :disabled="isDisabled(fieldId)" v-if="validateMode()">
    <LabelComponent v-if="label" class="form-label" :label="$t(label)" :label-for="'rangeinput-' + id" />
    <div class="col-md-8">
      <input
        :id="'range-' + id"
        v-model="value"
        type="range"
        class="form-range"
        :min="minValue"
        :max="maxValue"
        :step="step"
        :name="'range-' + id"
      >
    </div>
    <div class="col-md-4">
      <input
        :id="'rangeinput-' + id"
        ref="niceValue"
        v-model="niceValue"
        type="text"
        class="form-control"
        tabindex="0"
        pattern="^\d.[\.|,][0-9]{1,2}$"
        :name="'rangeinput-' + id"
        @focus="onFocus()"
        @blur="updateValue()"
        :data-testid="fieldId + '-input'"
      >      
    </div>
    <p v-if="isNumberError" class="form-text error-text">
      {{ $t('message.onlyNumbers') }}
    </p>
  </fieldset>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import LabelComponent from '@/components/form/LabelComponent.vue'
import { mapState } from 'vuex';
import { FormPayload } from '@/store/store';
import { ComponentMixins } from './component-mixins';
import emitter from "@/services/emitter";
import { ValidLocales } from "@/enums/Application";
import Swal from "sweetalert2";

export default defineComponent({
  name: 'InputRangeComponent',
  components: {
    LabelComponent
  },
  mixins: [ComponentMixins],
  props: {
    min: { type: Number, required: true },
    max: { type: Number, required: true },
    step: { type: Number, default: 0.1 },
    label: { type: String, required: true },
    type: { type: String, required: true },
    fieldId: { type: String, required: true },
    format: { type: String, default: 'EUR' },
    showInInput: { type: Boolean },
    defaultValue: { type: Number, default: 0 },
    isExpanded: { type: Boolean, default: false }
  },
  data() {
    return {
      id: Math.ceil(Math.random() * 1000000),
      niceValue: '0.00',
      isNumberError: 0,
    }
  },
  computed: {
    ...mapState(['employer', 'formDefaults']),
		value: {
			get() {
				return this.$store.state.formValues[this.fieldId];
			},
			set(value: string) {
				this.$store.commit('setFormValue', {key: this.fieldId, value: parseFloat(value)} as FormPayload);
				this.$emit('update:modelValue', value);
        emitter.emit("refreshStore");
			}
		},
    minValue() { return this.getMin(); },
    maxValue() { return this.getMax(); },
    expandedMode() {
      return this.$store.state.formValues.formMode
    }
  },
	watch: {
    value(newVal: number) {
        if (/^[0-9.,]+$/.test(newVal.toString())) {
          this.isNumberError = 0;
        } else {
          this.isNumberError = 1;
        }

        // Update the RRP Price accordingly to the total price
        if (this.fieldId === 'bikePrice') {
          this.$store.commit('setFormValue', {
            key: 'recommendedPurchasePrice',
            value: parseFloat(this.value)
          } as FormPayload);
          emitter.emit("refreshStore");
        }

        this.niceValue = this.$store.state.formValues[this.fieldId];
        this.formatToNiceValue();
    },
    niceValue(newVal: string) {
      // Allow both comma and dot as decimal separator by replacing the comma with a dot
      this.niceValue = newVal.replace(',', '.');
    }
  },
  created() {
    this.value = this.$store.state.formValues[this.fieldId];
    this.formatToNiceValue();
  },
  methods: {
    getMin(): number {
      if(this.formDefaults.get(this.fieldId)) {
        if(this.value < this.formDefaults.get(this.fieldId).minValue)
          this.value = this.formDefaults.get(this.fieldId).minValue;
        return this.formDefaults.get(this.fieldId).minValue;
      }
      return this.min;
    },
    getMax(): number {
      if(this.formDefaults.get(this.fieldId)) {
        if(this.value > this.formDefaults.get(this.fieldId).maxValue)
          this.value = this.formDefaults.get(this.fieldId).maxValue;
        return this.formDefaults.get(this.fieldId).maxValue;
      }
      return this.max;
    },
    onFocus() {
      this.niceValue = this.value.toString();
    },
    checkLength() {
      this.value = Number(this.niceValue);
      if(Number(this.niceValue) > this.maxValue) {
          Swal.fire({
            title: this.$t('message.incorrectPurchasePrice'),
            text: this.$t('message.incorrectPurchasePriceRangeDetails'),
            icon: "error",
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn-swal-error",
            },
            confirmButtonText: this.$t('message.correctInput')
          });
          this.niceValue = this.maxValue.toString();
      }


      if(Number(this.niceValue) < this.minValue) {

          Swal.fire({
            title: this.$t('message.incorrectPurchasePrice'),
            text: this.$t('message.incorrectPurchasePriceRangeDetails'),
            icon: "error",
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn-swal-error",
            },
            confirmButtonText: this.$t('message.correctInput')
          });
          this.niceValue = this.minValue.toString();

      }
    },
    updateValue() {
      this.checkLength();
      this.$store.commit('setFormValue', {key: this.fieldId, value: parseFloat(this.niceValue)} as FormPayload);
      this.$emit('update:modelValue', this.niceValue);
      this.formatToNiceValue();
    },
    formatToNiceValue() {
      const val = this.$store.state.formValues[this.fieldId];
      if (this.format === 'percent') {
        this.niceValue = `${val} %`;        
      } 
      else {
        const formatter = new Intl.NumberFormat(ValidLocales.GERMANY, {
          style: 'currency',
          currency: 'EUR',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
        this.niceValue = formatter.format(val);
      }
    },
    validateMode() {

      const alwaysVisible = [
        "bikePrice",
        "recommendedPurchasePrice",
        "maximumNetCharge",
        "monthlyIncome"
      ];

      if(alwaysVisible.includes(this.fieldId)) {
        return true;
      } else {
        return (this.isExpanded && !this.expandedMode ? false : true);
      }
    },
  }
})
</script>

<style lang="scss">

.error-text {
  color: red;
}

.btn-swal-error {
  background-color: #fff;
  color: #545454;
  font-weight: bold;
  border: 2px solid #545454;
  padding: 10px 10px;
  text-transform: uppercase;
  border-radius: 4px;

  &:hover {
    background-color: #f8f7f7;
  }
}

</style>