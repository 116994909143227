<template>
  <div class="home">
    <CalculatorElement />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import CalculatorElement from '@/components/CalculatorElement.vue'


export default defineComponent({
  name: 'IframeView',
  components: {
    CalculatorElement
  }
})

</script>
