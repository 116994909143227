export const de_DE = {
    "message": {
        "mandatory": "Pflichtfeld | Pflichtfelder",
        "translation": "Übersetzung | Übersetzungen",
        "step": "Schritt | Schritte",
        "reset": "Daten zurücksetzen",
        "please-choose": "Bitte wählen",
        "company": "Firma",
        "company-code": "Firmencode",
        "company-code-enter": "Haben Sie schon einen Firmencode?",
        "company-code-enter-hint": "Diesen erhalten Sie, wenn Ihr Arbeitgeber bereits einen Vertrag mit Bikeleasing geschlossen hat. Wenn das nicht der Fall ist, fahren Sie ohne Firmencode fort.",
        "next": "weiter",
        "prev": "zurück",
        "close": "schließen",
        "continue-wo-code": "Ohne Code fortfahren",
        "continue-w-code": "Mit Code fortfahren",
        "submit": "Leasing berechnen",
        "formNotFound": "Das Formular konnte nicht gefunden werden.",
        "month": "Monate",
        "sozialversicherungKonstant": "Sozialversicherungsbeiträge",

        "resultTableHeadline": "Berechnung der Leasingrate",
        "productBadgeBigText": "Ersparnis",
        "productBadgeSmallText": "gegenüber Direktkauf",
        "quickOverviewTd1" : "Monatliche Rate",
        "quickOverviewTd2" : "Monatliche Leasingrate inkl. Versicherung",
        "quickOverviewTd3" : "Monatliche Gehaltsumwandlung",
        "quickOverviewTd4" : "Tatsächliche Nettobelastung",
        "quickOverviewTd5" : "ERSPARNIS GEGENÜBER DEM DIREKTKAUF",

        "costOverview": "Kostenübersicht",
        "table1AccordionHeader" : "Vergleichsrechnung Fahrradkauf & Leasing",
        "table2AccordionHeader" : "Vergleich zum Eigenkauf",
        "tableHead1" : "Vergleichsrechnung",
        "tableHead2" : "Kauf",
        "tableHead3" : "Leasing",

        "monthlyIncome": "Bruttomonatsgehalt",
        "calculatedCostEmployeeLeasing": "Entgeltumwandlung der Leasingrate (ohne AGAV)",
        "calculatedCostEmployeeLeasingAt": "Entgeltumwandlung der Leasingrate (inkl. AGAV)",
        "calculatedCostEmployeeInsurancePremium": "Entgeltumwandlung der Fahrrad-Versicherung Bikeleasing-Komfort-Versicherung",
        "calculatedCostEmployeeInsurancePremiumPlus": "Entgeltumwandlung der Fahrrad-Versicherung Bikeleasing-Verschleiß-Versicherung",
        "calculatedCostEmployeeInsuranceInspection": "Entgeltumwandlung des Bikeleasing-Inspektionspaket",
        "calculatedCostEmployerLeasing": "Arbeitgeberanteil an Leasingrate in Euro",
        "calculatedCostEmployerInsurancePremium": "Arbeitgeberanteil an Versicherungspaket Bikeleasing-Komfort-Versicherung",
        "calculatedCostEmployerInsurancePremiumPlus": "Arbeitgeberanteil an Versicherungspaket Bikeleasing-Verschleiß-Versicherung",
        "calculatedCostEmployerInsuranceInspection": "Arbeitgeberanteil des Bikeleasing-Inspektionspaket",
        "calculatedGrossIncomeAfterCalculation": "Bruttoentgelt nach Umwandlung",
        "calculatedGeldwerterVorteil": "Geldwerter Vorteil dazu",

        "calculatedVersteuerbaresEinkommen": "Grundlage der Steuerberechnung",
        "calculatedLohnsteuer": "Lohnsteuer",
        "calculatedSolidaritaetszuschlag": "Solidaritätszuschlag",
        "calculatedKirchensteuer": "Kirchensteuer",
        "calculatedKrankenversicherung": "Krankenversicherung",
        "calculatedPflegeversicherung": "Pflegeversicherung",
        "calculatedRentenversicherung": "Rentenversicherung",
        "calculatedAeitslosenversicherung": "Arbeitslosenversicherung",

        "comparisonNetIncome": "Nettogehalt",
        "calculatedGeldwerterVorteilAbzug": "Abzgl. geldwerter Vorteil",
        "calculatedAuszahlungsbetrag": "Auszahlungsbetrag",
        "calculatedNettoBelastung": "REALE NETTOBELASTUNG",

        "totalsTotalLeasingRates": "Gesamtraten, anhand der Nettobelastung, während der",
        "calculatedRemainingBikeValue": "Kauf- bzw. Übernahmepreis durch den Mitarbeiter",
        "totalsTotalCostOfLeasing": "Gesamtkosten (inkl. Versicherung) nach",
        "savingsAbsolute": "RELATIVE ERSPARNIS",

        "calculatedMonatlicheGehaltsumwandlung": "Entgeltumwandlung der Leasingrate",

        "selfemployedTotalsTotalLeasingRates": "Monatliche Leasingrate exkl. Versicherung",

        "selfemployedTotalsTotalCostOfInsurance": "Monatliche Versicherungsrate Bikeleasing-Komfort-Versicherung",
        "selfemployedCostOfInsurancePremiumPlus": "Monatliche Versicherungsrate für Bikeleasing-Verschleiß-Versicherung",
        "selfemployedCostOfInsuranceInspection": "Monatliche Versicherungsrate für Bikeleasing-Inspektionspaket",

        "maxBikePrice": "Maximaler Fahrrad-Preis",
        "contributionToPrivateHealthCareInsurance": "Beitrag zur privaten Krankenversicherung/Pflegeversicherung",

        "selfemployedTotalsTotalCostOfLeasing": "Monatliche Leasingrate inkl. Versicherung",

        "change": "ändern",
        "invalidCode": "Code ist ungültig",
        "onlyNumbers": "Nur Zahlen erlaubt!",

        "normal": "Normaler Modus",
        "expanded": "Erweiterter Modus",

        "incorrectPurchasePrice": "Falscher Kaufpreis",
        "incorrectPurchasePriceDetails": "Der Kaufpreis darf nicht höher als der UVP-Preis sein, kontrollieren Sie bitte Ihre Angaben.",
        "incorrectPurchasePriceRangeDetails": "Der gewählte Kaufpreis entspricht nicht dem von Ihrem Arbeitgeber festgelegten Preisrange.",
        "correctInput": "Angabe korrigieren",
        "result": {
            "special": {
                "festo": {
                    "MonatlicheLeasingrate": "Monatliche Leasingrate",
                    "ZuschussFestoEur": "Zuschuss Festo in Euro",
                    "EinsetzbareZulage": "Einsetzbare Zulage",
                    "KostenFesto": "Kosten Fahrradkauf & Leasing für Festo Mitarbeiter",
                    "GrundlageSteuerberechnung": "Grundlage der Steuerberechnung",
                    "MonatlicheFestoLeistungszulage": "Monatliche Festo Leistungszulage",
                    "BarlohnumwandlungLeasingrate": "Barlohnumwandlung der Leasingrate",
                    "VergleichEigenkauf": "Vergleich zum Eigenkauf",
                    "VerwendeteFestoLeistungszulage": "Verwendete Festo Leistungszulage (Gesamt)",
                    "nurNet": "(nur Nettobelastung)",
                    "durchMitarbeiter": "bzw. Übernahme durch den Mitarbeiter",
                    "FestoLeasingrechner": "Festo-Leasingrechner",
                    "zNettoabzug": "Zusätzlicher Nettoabzug"
                }
            }
        },
        "validate": {
            "typeOfWork": {
                "title": "Art der Beschäftigung wurde nicht gewählt",
                "text": "Bitte wählen Sie eine Beschäftigungsart aus"
            }
        },

        "agavBonusIncludedInLeasingRate": "AGAV Prämie (in Leasingfaktor enthalten)"
    }
};
