<template>
    <span class="cursor-pointer" @click="toggleModalDialog" v-if="modal === 1 || displayEditButton">
        <span id="company-change-icon" class="text-black h5" :title="$t('message.change')"><span class="icon-pencil" style="padding-left: 5px; font-size: 0.7em" /></span>
    </span>
</template>
  
  <script lang="ts">
  import { defineComponent, PropType } from "vue";
  
  export default defineComponent({
    name: 'ChangeCompanyButton',
    props: {
      modal: { type: Number as PropType<number>, required: true },
      toggleModal: { type: Function, required: true },
      displayEditButton: { type: Boolean, required: false, default: true }
    },
    methods: {
        toggleModalDialog() {
            this.toggleModal();
        }
    }
  });
  </script>
  
  <style lang="scss">
  @import "@/styles/misc/icons.scss";
  </style>