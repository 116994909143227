import { IApiPayloadCollectveAgreement } from "@/interfaces/api-payload-collective-agreement";
import { IEmployer } from "@/models/IEmployer";
import { BIKE_TYPES, HEALTHCARE_TYPE, IFormValues } from "@/store/interfaces/IFormValues";
import { State } from "@/store/store";
import { mapToggleValueToEmploymentRole } from "@/mapper/employment-role.mapper";
import { GERMAN_STATES, STANDARD_BIKE_PRICES } from "@/enums/CalculatorConstants";

export function mapFormPayloadCollectiveAgreement(data: IFormValues, employer: IEmployer = null, state: State = null): IApiPayloadCollectveAgreement {
    return {
        bikeData: {
            bikePrice: data.bikePrice ?? 649,
            recommendedPurchasePrice: data.recommendedPurchasePrice ?? 649,
            leasingTime: data.leasingTime ?? 36,
            maximumNetCharge: data.maximumNetCharge ?? 0,
            bikeType: data.bikeType ?? BIKE_TYPES.PEDELEC,
            distanceToWorkplace: data.distanceToWorkplace ?? 0,
            firstBike: data.firstBike,
        },
        employeeData: {
            healthcareType: data.healthcareType ?? HEALTHCARE_TYPE.PUBLIC,
            privateHealthInsuranceContribution: data.privateHealthInsuranceContribution ?? 0,
            privateCareInsuranceContribution: data.privateCareInsuranceContribution ?? 0,
            healthcareSurcharge: data.healthcareSurcharge ?? 1,
            monthlyIncome: data.monthlyIncome ?? 0,
            children: data.children ?? -1,
            taxClass: data.taxClass ?? 1,
            churchTax: data.employeechurchtax as boolean ?? false,
            pensionTax: data.pensionTax,
            state: data.employeedistrict as GERMAN_STATES ?? GERMAN_STATES.BADEN_WUERTTEMBERG,
            bonus: data.bonus ?? 0,
            extraExpenses: data.extraExpenses ?? 0,
            typeOfWork: data.typeOfWork,
        },
        employerData: {
            companyCode: data.companyCode ?? employer?.companyCode  ?? '',
            agavIncludedInLeasingrate: employer?.agavIncludedInLeasingrate ?? true,
            employerTaxDeduction: data.employerTaxDeduction ?? employer?.employerTaxDeduction ?? true,
            leasingFactor36months: employer?.leasingFactor36months ?? null,
            leasingFactor48months: employer?.leasingFactor48months ?? null,
            leasingShareEmployer: data.leasingShareEmployer ?? employer?.leasingShareEmployer ?? 0,
            leasingShareEmployerPercentage: data.leasingShareEmployerPercentage ?? employer?.leasingShareEmployerPercentage ?? 0,
            minBikePrice: (state && state.formDefaults.get("bikePrice").minValue) ?? STANDARD_BIKE_PRICES.MIN,
            maxBikePrice: (state && state.formDefaults.get("bikePrice").maxValue) ?? STANDARD_BIKE_PRICES.MAX,
        },
        bikeInsuranceData: {
            wearAndTear: data.wearAndTear ,
            comfort: data.comfort,
            inspection: data.inspection,
            inspectionPaidBy: mapToggleValueToEmploymentRole(data.inspectionPaidBy),
            comfortPaidBy: mapToggleValueToEmploymentRole(data.comfortPaidBy),
            wearAndTearPaidBy: mapToggleValueToEmploymentRole(data.wearAndTearPaidBy),
        }
    };
}