export const en_US = {
    "message": {
        "mandatory": "Mandatory field | Mandatory fields",
        "translation": "Translation | Translations",
        "step": "Step | Steps",
        "reset": "Reset",
        "please-choose": "Please choose",
        "company": "Company",
        "company-code": "Company-Code",
        "company-code-enter": "Do you already have a company code?",
        "company-code-enter-hint": "You will receive this if your employer has already concluded a contract with Bikeleasing. If this is not the case, continue without a company code.",
        "next": "next",
        "prev": "back",
        "close": "Close",
        "continue-wo-code": "Proceed without code",
        "continue-w-code": "Check Code",
        "submit": "Calculate Leasing",
        "formNotFound": "The form could not be found.",
        "month": "Months",
        "sozialversicherungKonstant": "Social security contributions (remain constant)",

        "resultTableHeadline": "Leasing rate calculation",
        "productBadgeBigText": "savings",
        "productBadgeSmallText": "versus direct purchase",
        "quickOverviewTd1" : "Monthly rate",
        "quickOverviewTd2" : "Monthly rate incl. insurance",
        "quickOverviewTd3" : "Monthly salary conversion",
        "quickOverviewTd4" : "Actual net charge",
        "quickOverviewTd5" : "SAVINGS COMPARED TO THE DIRECT PURCHASE",

        "costOverview": "Cost overview",
        "table1AccordionHeader" : "Comparative calculation of bicycle purchase & leasing",
        "table2AccordionHeader" : "Compared to purchasing",
        "tableHead1" : "Comparative calculation",
        "tableHead2" : "Purchase",
        "tableHead3" : "Leasing",

        "monthlyIncome": "Gross monthly salary",
        "calculatedCostEmployeeLeasing": "Conversion of the leasing rate (without AGAV)",
        "calculatedCostEmployeeLeasingAt": "Conversion of the leasing rate (with AGAV)",
        "calculatedCostEmployeeInsurancePremium": "Premium all-round protection premium for bicycle insurance",
        "calculatedCostEmployeeInsurancePremiumPlus": "Conversion of premium for bicycle insurance PREMIUMPLUS \"Wear\"",
        "calculatedCostEmployeeInsuranceInspection": "Fee conversion of the inspection package",
        "calculatedCostEmployerLeasing": "Employer share of leasing rate in euros",
        "calculatedCostEmployerInsurancePremium": "Employer contribution to premium all-round protection insurance package",
        "calculatedCostEmployerInsurancePremiumPlus": "Employer contribution to insurance package PREMIUMPLUS \"Wear\"",
        "calculatedCostEmployerInsuranceInspection": "Employer's portion of inspection package",
        "calculatedGrossIncomeAfterCalculation": "Gross fee after conversion",
        "calculatedGeldwerterVorteil": "Monetary benefit",

        "calculatedVersteuerbaresEinkommen": "Basis of the tax calculation",
        "calculatedLohnsteuer": "Income tax",
        "calculatedSolidaritaetszuschlag": "Solidarity surcharge",
        "calculatedKirchensteuer": "Church tax",
        "calculatedKrankenversicherung": "Health insurance",
        "calculatedPflegeversicherung": "Care insurance",
        "calculatedRentenversicherung": "Pension insurance",
        "calculatedAeitslosenversicherung": "Unemployment insurance",

        "comparisonNetIncome": "Net salary",
        "calculatedGeldwerterVorteilAbzug": "Less pecuniary advantage",
        "calculatedAuszahlungsbetrag": "Payout amount",
        "calculatedNettoBelastung": "REAL NET LOAD",

        "totalsTotalLeasingRates": "Overall rates (net charge) during the",
        "calculatedRemainingBikeValue": "Purchase or takeover price by the employee",
        "totalsTotalCostOfLeasing": "Total costs (incl. insurance) according to",
        "savingsAbsolute": "RELATIVE SAVINGS",

        "calculatedMonatlicheGehaltsumwandlung": "Conversion of the leasing rate",

        "selfemployedTotalsTotalLeasingRates": "Monthly leasing rate excl. insurance",

        "selfemployedTotalsTotalCostOfInsurance": "Monthly insurance premium all-round protection",
        "selfemployedCostOfInsurancePremiumPlus": "Monthly insurance Bikeleasing-Verschleiß-Versicherung",
        "selfemployedCostOfInsuranceInspection": "Monthly insurance Inspection package",

        "maxBikePrice": "Maximum bike price",
        "contributionToPrivateHealthCareInsurance": "Contribution to private health insurance/care insurance",

        "selfemployedTotalsTotalCostOfLeasing": "Monthly leasing rate including insurance",

        "change": "change",
        "invalidCode": "Invalid code",
        "onlyNumbers": "Only numbers are allowed!",

        "normal": "Normal Modus",
        "expanded": "Expanded Modus",

        "incorrectPurchasePrice": "Incorrect purchase price",
        "incorrectPurchasePriceDetails": "The purchase price must not be higher than the RRP price, please check your information.",
        "incorrectPurchasePriceRangeDetails": "The purchase price chosen does not correspond to the price range set by your employer.",
        "correctInput": "Correct input",
        "result": {
            "special": {
                "festo": {
                    "MonatlicheLeasingrate": "Monthly leasing rate",
                    "ZuschussFestoEur": "Grant Festo in Euro",
                    "EinsetzbareZulage": "Usable allowance",
                    "KostenFesto": "Cost of bicycle purchase & leasing for Festo employees",
                    "GrundlageSteuerberechnung": "Basis of the tax calculation",
                    "MonatlicheFestoLeistungszulage": "Monthly Festo performance bonus",
                    "BarlohnumwandlungLeasingrate": "Cash wage conversion of the leasing rate",
                    "VergleichEigenkauf": "Compared to own purchase",
                    "VerwendeteFestoLeistungszulage": "Festo performance allowance used (total)",
                    "nurNet": "(only net debit)",
                    "durchMitarbeiter": "or taken over by the employee",
                    "FestoLeasingrechner": "Festo leasing calculator",
                    "zNettoabzug": "Additional net deduction"
                }
            }
        },
        "validate": {
            "typeOfWork": {
                "title": "Type of work was not selected",
                "text": "Please select your type of work"
            }
        },

        "agavBonusIncludedInLeasingRate": "AGAV bonus (included in leasing factor)",
        "oldCalculationDateHint": "Before 01.01.2023: "
    }
};
