<template>
  <fieldset :class="'form-check form-switch mt-3 field-is-' + type" :disabled="isDisabled(fieldId)" v-if="validateMode()">
    <input :id="fieldId" v-model="value" type="checkbox" class="form-check-input" :name="fieldId" 
		   :data-testid="fieldId + '-toggle'"/>
    <LabelComponent v-if="label" class="form-check-label" :label="label" :label-for="fieldId">
      <template #after>
        <Tooltip v-if="tooltip" :content="tooltip[$i18n.locale]"  />
        <p v-if="value" class="fs-5 text-dark">{{ statusOn[$i18n.locale] }}</p>
        <p v-else class="fs-5 text-dark">{{ statusOff[$i18n.locale] }}</p>
      </template>
    </LabelComponent>
  </fieldset>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Tooltip from '@/components/TooltipComponent.vue'
import LabelComponent from '@/components/form/LabelComponent.vue'
import { FormPayload } from '@/store/store';
import { ComponentMixins } from './component-mixins';
import emitter from "@/services/emitter";

export default defineComponent({
	name: 'InputToggleComponent',
	components: {
		Tooltip,
		LabelComponent
	},
	mixins: [ComponentMixins],
	props: {
		modelValue: {type: Boolean || Number},
		type: { type: String, required: true },
		label: { type: String, default: '' },
		fieldId: { type: String, required: true },
		tooltip: { type: Object, default: null },
		subfields: { type: Object, default: null },
		statusOn: { type: Object, required: true },
		statusOff: { type: Object, required: true },
		isExpanded: { type: Boolean, default: false }
	},
	emits: ['update:modelValue'],
	data() {
		return {
			id: Math.ceil(Math.random() * 1000000),
		}
	},
	computed: {
		value: {
			get(): boolean {
				return Boolean(this.$store.state.formValues[this.fieldId]);
			},
			set(value: boolean) {
          let fieldValue:boolean | number = value;
          switch (this.fieldId) {
              case 'comfortPaidBy':
                  fieldValue = Number(value);
                  break;
              case 'inspectionPaidBy':
                  fieldValue = Number(value);
                  break;
              case 'wearAndTearPaidBy':
                  fieldValue = Number(value);
                  break;
              default:
                  break;
          }

				this.$store.commit('setFormValue', {key: this.fieldId, value: fieldValue} as FormPayload);
				this.$emit('update:modelValue', value);
				emitter.emit("refreshStore");
			}
		},
		expandedMode() {
			return this.$store.state.formValues.formMode
		}
	},
	created() {
		this.value = Boolean(this.$store.state.formValues[this.fieldId]);
	},
	methods: {
		validateMode() {
			return (!(this.isExpanded && !this.expandedMode));
		}
	}
})
</script>
