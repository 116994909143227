<template>
  <span :class="'icon icon-'+icon+' fs-'+size" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'IconComponent',
  props: {
    icon: { type: String, required: true },
    size: { type: String, default: "6" },
  },
});
</script>

<style lang="scss">
@import "@/styles/misc/icons.scss";
</style>