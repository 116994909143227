<template>
  <Modal ref="modal" :modal-title="t('message.company-code-enter')">
    <template #body>
      <div class="form-floating">
        <input
          :id="'input-' + id"
          type="text"
          v-model="companyCode"
          class="form-control"
          :placeholder="t('message.company-code')"
          :name="'input-' + id"
          @input="handleCompanyCodeInput"
          data-testid="company-code-input"
        >
        <Label
          :label="t('message.company-code')"
          :label-for="'input-' + id"
        />
      </div>
      <p v-if="!companyFound && companyCode" class="text-warning mt-2">
        {{ t('message.invalidCode') }}
      </p>
    </template>
    <template #footer>
      <div class="hint-company-code">
      <p >{{ t('message.company-code-enter-hint') }}</p>
      </div>
      <div>
      <button
        id="btn-no-code"
        v-if="!companyFound"
        ref="companywo"
        class="btn btn-light"
        type="button"
        @click="handleContinueWithoutCode"
        data-testid="without-company-code-button"
      >
        {{ t('message.continue-wo-code') }}
      </button>
      </div>
    </template>
  </Modal>	
</template>

<script setup lang="ts">
import Label from '@/components/form/LabelComponent.vue'
import Modal from '@/components/ModalComponent.vue'
import { store } from '@/store/store';
import { computed,onMounted, ref } from "vue";
import { useTranslator } from "@/composables/useTranslator";

const { t } = useTranslator();
const emits = defineEmits(['hideModal'])

const id = ref<number>(Math.ceil(Math.random()*1000000))
const companyCode = ref<string>('');
const companyFound = computed<boolean>(() => store.state.employer.company !== '' && store.state.employer.company !== '---');

const handleCompanyCodeInput = async () => {
  if (companyCode.value.length > 3 && companyCode.value.length <= 6) {

    const attemptSetEmployer = await store.dispatch('setEmployerByCompanyCode', companyCode.value.toUpperCase())
    if (attemptSetEmployer) {
      emits('hideModal')
    }
  }
}

const handleContinueWithoutCode = () => {
  emits('hideModal')
}


onMounted(async () => {
  await store.dispatch('resetEmployer');
})

</script>


<style lang="scss">
@import "@/styles/elements/form/_all.scss";

.hint-company-code {
  font-size: 12px;
}
</style>