import {FormMode, VALID_COUNTRIES, ValidLocales} from "@/enums/Application";
import {State} from '@/store/store';
import {mapFormLimitsValues} from './form-limits-mapper';
import {insuranceMapping} from './insurance-mapping';
import {STANDARD_BIKE_PRICES} from "@/enums/CalculatorConstants";
import {employerConditionMapping} from "@/mapper/employer-conditions-mapper";
import {HEALTHCARE_TYPE} from "@/store/interfaces/IFormValues";
const germanForms = [FormMode.COLLECTIVE_AGREEMENT, FormMode.OFFICIAL, FormMode.EMPLOYEE, FormMode.SELF_EMPLOYED]


export function mapFormDefaultValues(state: State): void {

    if(state.appData.formType === FormMode.OFFICIAL) {
        state.formValues.healthcareType = HEALTHCARE_TYPE.PRIVATE_WITHOUT_CONTRIBUTION;
        state.formValues.employeechurchtax = 'no';
        if(state.appData.country === VALID_COUNTRIES.AUSTRIA) {
            state.appData.formType = null;
        }
        state.formValues.inspection = false;
        state.formValues.privateHealthInsuranceContribution = 0;
        state.formValues.privateCareInsuranceContribution = 0;
      }

    if(germanForms.includes(state.appData.formType)) {
      state.formDefaults.set('leasingTime', {
        disabled: true,
      })
    }

      // Handle AT default values
      if(state.appData.country.toLowerCase() === VALID_COUNTRIES.AUSTRIA) {
        state.formValues.leasingTime = 48;
        state.formValues.bikePrice = 2699;
        state.formValues.recommendedPurchasePrice = 2699;
      }

      if([FormMode.SELF_EMPLOYED, FormMode.SELF_EMPLOYED_AT].includes(state.appData.formType)) {
        state.formValues.inspection = false;
      }

      if(state.appData.formType === FormMode.COLLECTIVE_AGREEMENT) {
        state.formValues.comfortPaidBy = 1;
        state.formDefaults.set("comfortPaidBy", {
            disabled: true,
        });
        state.formDefaults.set("inspectionPaidBy", {
            disabled: true,
        });
        state.formDefaults.set("wearAndTearPaidBy", {
            disabled: true,
        });
        state.formValues.wearAndTear = false;
        state.formDefaults.set("wearAndTear", {
            disabled: false
        });
        state.formValues.employeedistrict = "BW";
      }

    const employer = state.employer;
    if(employer && employer.companyCode !== '' && employer.companyCode !== null) {
        // Set country
      if (employer.country === VALID_COUNTRIES.AUSTRIA) {
        state.appData.country = VALID_COUNTRIES.AUSTRIA;
        state.appData.lang = ValidLocales.AUSTRIA;
      }
        
        // employerTaxDeduction
        state.formValues.employerTaxDeduction = employer.employerTaxDeduction;
        state.formDefaults.set("employerTaxDeduction", {
            disabled: employer.employerTaxDeduction !== undefined,
        });

        const minBikePrice = employer.minBikePrice || STANDARD_BIKE_PRICES.MIN;
        const maxBikePrice = employer.maxBikePrice || STANDARD_BIKE_PRICES.MAX;

        // total price
        if (state.appData.country === VALID_COUNTRIES.AUSTRIA) {
          state.formDefaults.set("bikePrice", {
            minValue: minBikePrice,
            maxValue: maxBikePrice,
          });
        } else {
          state.formDefaults.set("bikePrice", {
            minValue: minBikePrice,
            maxValue: maxBikePrice,
          });
        }

        // totalPriceDivergent
        state.formDefaults.set("recommendedPurchasePrice", {
            minValue: minBikePrice,
            maxValue: STANDARD_BIKE_PRICES.MAX,
        });

        // Employer contributions (absolut or percentage)
        state.formValues.leasingShareEmployerPercentage = employer.leasingShareEmployerPercentage || 0;
        state.formValues.leasingShareEmployer = employer.leasingShareEmployer ?? 0;
        state.formDefaults.set("leasingShareEmployer", {
            disabled: employer.leasingShareEmployer > 0,
        });

        // Insurance mapping
        insuranceMapping(state, employer);

        // Employer conditions
        employerConditionMapping(state, employer);
      }

      mapFormLimitsValues(state);

}
