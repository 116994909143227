import { State } from "@/store/store";
import { useRoute } from "vue-router";
import { Color, Rgb } from "@/interfaces/colors";
import { store } from "@/store/store";
import { AxiosResponse } from "axios";
import { IEmployer } from "@/models/IEmployer";
import { StandardColors } from "@/enums/Application";


export class ColorSetting {
    public primaryColor: Color;
    public secondaryColor: Color;

    setPropertyValueByName(name: string, hex: string, rgb: Rgb) {
        switch(name) {
            case 'primaryColor':
                this.primaryColor = {
                    hex, rgb
                };
                break;
            case 'secondaryColor':
                this.secondaryColor = {
                    hex, rgb
                };
                break;
        }
    }
}

function hexToRgb(hex: string): Rgb | null {

    if (typeof hex !== 'string') {
       throw TypeError("Invalid hex color type. it must be a string");
    }

    hex = hex.trim();

    // If we don't have enough data don't bother to continue
    if (hex.length < 3) {
    return null
    }

    // If a triplet is passed, first convert to full HEX notation
    const shortHexClosure = (m: string, r: string, g: string, b: string) => {
        return r + r + g + g + b + b;
    }
    hex = hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, shortHexClosure);

    // Convert HEX to RGB
    const replacementPattern = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i;
    const conversionResult = replacementPattern.exec(hex);

    // Create RGB object and return it
    return conversionResult ? {
        r: parseInt(conversionResult[1], 16),
        g: parseInt(conversionResult[2], 16),
        b: parseInt(conversionResult[3], 16)
    } : null;
}

export function getColoringFromUrl(): void {
    const $route = useRoute();
    const colorSettings = new ColorSetting();

    ['primaryColor', 'secondaryColor'].forEach((item: string) => {
        const color = $route.query[item] ? $route.query[item].toString() : '';
        if(color && color !== '') {
            const hex = color.startsWith('#') ? color : `#${color}`;
            const rgb = hexToRgb(hex);
            if(!rgb) {
                console.error(`Invalid color ${hex} for ${item}`);
                return null
            }
            colorSettings.setPropertyValueByName(item, hex, rgb);
        }
    });

    if(colorSettings.primaryColor === undefined || colorSettings.secondaryColor === undefined) {
        return null;
    }

    store.dispatch('setEmployerColors', [colorSettings.primaryColor, colorSettings.secondaryColor]);
}

export function getColoringFromEmployer(state: State): ColorSetting {
    const colorSettings = new ColorSetting();
    const employer = state.employer;
    if(employer.primaryColor && employer.secondaryColor) {
        ['primaryColor', 'secondaryColor'].forEach((item: string) => {
            const color = item === 'primaryColor' ? employer.primaryColor : employer.secondaryColor;
            if(color && color !== '') {
                const rgb = hexToRgb(color);
                if(!rgb) {
                    return null
                }
                colorSettings.setPropertyValueByName(item, color, rgb);   
            }
        });
        if(colorSettings.primaryColor === undefined || colorSettings.secondaryColor === undefined) {
            return null;
        }
    }       
    return colorSettings;
}

const checkCompanySpecificColors = (response: AxiosResponse<IEmployer>): AxiosResponse<IEmployer> => {

    if (!response.data.primaryColor || !/^#[0-9A-F]{6}$/i.test(response.data.primaryColor)) {
        response.data.primaryColor = StandardColors.PRIMARY;
    }

    if (!response.data.secondaryColor || !/^#[0-9A-F]{6}$/i.test(response.data.secondaryColor)) {
        response.data.secondaryColor = StandardColors.SECONDARY;
    }

    return  response
}


export { hexToRgb, checkCompanySpecificColors };