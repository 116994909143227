import { ApiPayloadSelfEmployed } from "@/interfaces/api-payloads";
import { BIKE_TYPES, IFormValuesSelfEmployed } from "@/store/interfaces/IFormValues";
import { State } from "@/store/store";
import { STANDARD_BIKE_PRICES } from "@/enums/CalculatorConstants";
import { IEmployer } from "@/models/IEmployer";

export function mapFormPayloadSelfEmployed(data: IFormValuesSelfEmployed, employer: IEmployer = null, state: State = null): ApiPayloadSelfEmployed {
    return {
        bikeData: {
            bikePrice: data.bikePrice,
            leasingTime: data.leasingTime,
            maximumNetCharge: data?.maximumNetCharge ?? 0,
            bikeType: data.bikeType ?? BIKE_TYPES.PEDELEC,
            distanceToWorkplace: 0,
        },
        employerData: {
            companyCode: (state && state.formValues.companyCode) ?? '',
            minBikePrice: (state && state.formDefaults.get("bikePrice").minValue) ?? STANDARD_BIKE_PRICES.MIN,
            maxBikePrice: (state && state.formDefaults.get("bikePrice").maxValue) ?? STANDARD_BIKE_PRICES.MAX,
            employerTaxDeduction: data.employerTaxDeduction ?? employer?.employerTaxDeduction ?? true,
            leasingFactor36months: employer?.leasingFactor36months ?? null,
            leasingFactor48months: employer?.leasingFactor48months ?? null,
        },
        bikeInsuranceData: {
            comfort: data.comfort ?? true,
            wearAndTear: data.wearAndTear ?? false,
            inspection: data.inspection ?? false,
        }
    };
}
