import { ref } from 'vue'

/**
 * Composable for handling URL parameters
 *
 */
export const useUrlParams = () => {

  /**
   *
   * Get the URL parameters and return an associative array of key value pairs
   * @returns {Object}
   *
   * @example
   * // URL: https://example.com?foo=bar&baz=qux
   * const params = getUrlParams(); // { foo: 'bar', baz: 'qux' }
   */
  const getUrlParams = () => {
    const params = ref<{[key: string]: string}>({})

    const searchParams = new URLSearchParams(window.location.search)
    params.value = Object.fromEntries(searchParams)
    return params
  }

  /**
   *
   * Get a specific URL parameter by key
   * @param param {string}
   *
   * @returns {string} The value of the URL parameter
   *
   * @example
   *
   * // URL: https://example.com?foo=bar
   * getUrlParam('foo') // returns 'bar'
   *
   */
  const getUrlParam = (param: string) : string | null => {
    const searchParams = new URLSearchParams(window.location.search)
    return searchParams.get(param)
  }


  /**
   *
   * Checks that the value of a URL parameter is equal to a given value
   * Please note that per default all URL parameters are strings
   *
   * @param param  {string} The URL parameter to check
   * @param expected {string} The expected value of the URL parameter
   *
   * @returns {boolean} True if the value of the URL parameter is equal to the expected value
   *
   *
   * @example
   * // URL: https://example.com?foo=bar
   * isUrlParam('foo', 'bar') // returns true
   * isUrlParam('foo', 'baz') // returns false
   * isUrlParam('foo', 123) // returns false at runtime, will throw a TypeScript error at compile time
   */
  const isUrlParam = (param: string, expected: string) : boolean => {
    const searchParam = getUrlParam(param)
    return searchParam === expected
  }

  return {
    getUrlParam,
    getUrlParams,
    isUrlParam
  }
}
