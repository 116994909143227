<template>
  <section class="section is-large my-3">
    <div class="container-fluid">
      <h1 data-testid="result-title-text">{{ $t("message.result.special.festo.FestoLeasingrechner") }}</h1>
      <div class="row mt-3 quick-overview">
        <div class="col-xs-12 col-md-3 text-right">
          <div class="product-badge hidden-md mb-2">
            <div class="inner-badge">
              <span class="percent"><span>{{formatToPercent(resultData.savings.percentageSavings)}}</span></span>
              <span class="text">
                <span class="bigtext">{{ $t("message.productBadgeBigText") }}</span>
                <span class="smalltext">{{ $t("message.productBadgeSmallText") }}</span>
              </span>
            </div>
          </div>
        </div>

        <div class="col-xs-12 col-md-9">
          <table class="table">
            <tr>
              <td>{{ $t("message.result.special.festo.MonatlicheLeasingrate") }}</td>
              <td />
              <td
                class="text-end"
                >{{formatToEuro(resultData.monthlyCosts.employeeCosts.monthlyLeasingRate)}}</td>
            </tr>
            <tr>
              <td>{{ $t("message.quickOverviewTd2") }}</td>
              <td />
              <td
                class="text-end"
                >{{calculateLeasingTotalValue(resultData)}}</td>
            </tr>
            <tr v-if="formData.typeOfWork === TYPE_OF_WORK.NON_COLLECTIVE_EMPLOYEE">
              <td><strong class="text-primary">{{ $t("message.quickOverviewTd3") }}</strong></td>
              <td />
              <td
                class="text-end bg-primary text-white bold"
                >{{formatToEuro(resultData.monthlyCosts.employeeCosts.salaryConversion)}}</td>
            </tr>
            <tr>
              <td><strong class="text-primary">{{ $t("message.result.special.festo.ZuschussFestoEur") }}</strong></td>
              <td />
              <td class="text-end" :class="[formData.typeOfWork === TYPE_OF_WORK.NON_COLLECTIVE_EMPLOYEE ? 'bg-primary text-white bold' : 'text-primary bold']">
                {{formatToEuro(resultData.monthlyCosts.employerCosts.leasingRate)}}
              </td>
            </tr>
            <tr v-if="formData.typeOfWork === TYPE_OF_WORK.COLLECTIVE_EMPLOYEE">
              <td><strong class="text-primary">{{ $t("message.result.special.festo.EinsetzbareZulage") }}</strong></td>
              <td />
              <td
                class="text-end text-primary bold"
                >{{formatToEuro(resultData.totalCosts.bonusUsage.used)}}</td>
            </tr>
            <tr>
              <td><strong class="text-primary">{{ $t("message.quickOverviewTd4") }}</strong></td>
              <td />
              <td class="text-end text-primary">
                <strong>{{formatToEuro(resultData.monthlyCosts.employeeCosts.netCharge)}}</strong>
              </td>
            </tr>
            <tr>
              <td><strong style="text-transform: uppercase;">{{ $t("message.productBadgeBigText") }}</strong></td>
              <td class="text-end">
                <strong><span>{{formatToPercent(resultData.savings.percentageSavings)}}</span></strong>
              </td>
              <td class="text-end">
                <strong>{{formatToEuro(resultData.savings.totalSavings)}}</strong>
              </td>
            </tr>
          </table>
        </div>

        <div class="col-xs-12">

            <strong
              class="accordion-head collapsed d-flex"
              role="button"
              :class="{ active: leasingTableActive }"
              @click="leasingTableActive = !leasingTableActive"
            >{{ $t("message.result.special.festo.KostenFesto") }}<span class="pull-right" /></strong>

            <div
              class="accordion-body"
              :class="{ active: leasingTableActive }"
            >
              <div class="responsive-table">
                <table class="table">
                  <tr>
                    <th>{{ $t("message.result.special.festo.GrundlageSteuerberechnung") }}</th>
                    <th class="text-end">
                      {{ $t("message.tableHead2") }}
                    </th>
                    <th class="text-end">
                      {{ $t("message.tableHead3") }}
                    </th>
                  </tr>
                  <tr>
                    <td>{{$t('message.monthlyIncome')}}</td>
                    <td
                      class="text-end bold">{{formatToEuro(formData.monthlyIncome)}}</td>
                    <td
                      class="field text-end bold">{{formatToEuro(formData.monthlyIncome)}}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("message.result.special.festo.MonatlicheFestoLeistungszulage") }}</td>
                    <td
                      class="text-end positive-number">{{formatToEuro(formData.bonus)}}</td>
                    <td
                      class="field text-end positive-number">{{formatToEuro(formData.bonus)}}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("message.result.special.festo.ZuschussFestoEur") }}</td>
                    <td class="text-end" />
                    <td
                      class="field text-end positive-number">{{formatToEuro(resultData.monthlyCosts.employerCosts.leasingRate)}}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("message.result.special.festo.BarlohnumwandlungLeasingrate") }}</td>
                    <td class="text-end" />
                    <td
                      class="field text-end negative-number">{{formatToEuro(resultData.monthlyCosts.employeeCosts.monthlyLeasingRate)}}</td>
                  </tr>
<!--                  <tr v-if="resultData.monthlyCosts.employerCosts.agavCosts">-->
<!--                    <td>-->
<!--                      {{ $t('message.agavBonusIncludedInLeasingRate') }}-->
<!--                    </td>-->
<!--                    <td class="text-end" />-->
<!--                    <td-->
<!--                      class="field text-end"-->
<!--                    >-->
<!--                      ({{formatToEuro(resultData.monthlyCosts.employerCosts.agavCosts)}})-->
<!--                    </td>-->
<!--                  </tr>-->
                  <tr>
                    <td>{{$t('message.calculatedGeldwerterVorteil')}}</td>
                    <td class="text-end" />
                    <td
                      class="field text-end positive-number">{{formatToEuro(resultData.taxCalculationWithLeasing.geldwerterVorteil)}}</td>
                  </tr>
                  <tr>
                    <td>{{$t('message.calculatedCostEmployeeInsurancePremium')}}</td>
                    <td class="text-end" />
                    <td
                      class="field text-end negative-number">{{formatToEuro(resultData.monthlyCosts.employeeCosts.insuranceComfort)}}</td>
                  </tr>
                  <tr>
                    <td>{{$t('message.calculatedCostEmployeeInsurancePremiumPlus')}}</td>
                    <td class="text-end" />
                    <td
                      class="field text-end">{{formatToEuro(resultData.monthlyCosts.employeeCosts.insuranceWearAndTear, true)}}</td>
                  </tr>
                  <tr>
                    <td>{{$t('message.calculatedCostEmployeeInsuranceInspection')}}</td>
                    <td class="text-end" />
                    <td
                      class="field text-end">{{formatToEuro(resultData.monthlyCosts.employeeCosts.inspection, true)}}</td>
                  </tr>
                  <tr>
                    <td>{{$t('message.calculatedCostEmployerInsurancePremium')}}</td>
                    <td class="text-end" />
                    <td
                      class="field text-end">{{formatToEuro(resultData.monthlyCosts.employerCosts.insuranceComfort, true)}}</td>
                  </tr>
                  <tr>
                    <td>{{$t('message.calculatedCostEmployerInsurancePremiumPlus')}}</td>
                    <td class="text-end" />
                    <td
                      class="field text-end">{{formatToEuro(resultData.monthlyCosts.employerCosts.insuranceWearAndTear)}}</td>
                  </tr>
                  <tr>
                    <td>{{$t('message.calculatedCostEmployerInsuranceInspection')}}</td>
                    <td class="text-end" />
                    <td
                      class="field text-end">{{formatToEuro(resultData.monthlyCosts.employerCosts.inspection)}}</td>
                  </tr>
                  <tr class="break">
                    <td>{{$t('message.calculatedVersteuerbaresEinkommen')}}</td>
                    <td
                      class="text-end">{{formatToEuro(formData.monthlyIncome + formData.bonus)}}</td>
                    <td
                      class="field text-end">{{formatToEuro(resultData.taxCalculationWithLeasing.taxableIncome)}}</td>
                  </tr>
                  <tr>
                    <td>{{$t('message.calculatedLohnsteuer')}}</td>
                    <td
                      class="text-end negative-number">{{formatToEuro(resultData.taxCalculationWithoutLeasing.incomeTaxAmount)}}</td>
                    <td
                      class="field text-end negative-number">{{formatToEuro(resultData.taxCalculationWithLeasing.incomeTaxAmount)}}</td>
                  </tr>
                  <tr>
                    <td>{{$t('message.calculatedSolidaritaetszuschlag')}}</td>
                    <td
                      class="text-end negative-number">{{formatToEuro(resultData.taxCalculationWithoutLeasing.solidarityContribution)}}</td>
                    <td
                      class="field text-end negative-number">{{formatToEuro(resultData.taxCalculationWithLeasing.solidarityContribution)}}</td>
                  </tr>
                  <tr>
                    <td>{{$t('message.calculatedKirchensteuer')}}</td>
                    <td
                      class="text-end negative-number">{{formatToEuro(resultData.taxCalculationWithoutLeasing.churchTaxAmount)}}</td>
                    <td
                      class="field text-end negative-number">{{formatToEuro(resultData.taxCalculationWithLeasing.churchTaxAmount)}}</td>
                  </tr>
                  <tr>
                    <td>{{$t('message.calculatedKrankenversicherung')}}</td>
                    <td
                      class="text-end negative-number">{{formatToEuro(resultData.taxCalculationWithoutLeasing.healthInsuranceAmount)}}</td>
                    <td
                      class="field text-end negative-number">{{formatToEuro(resultData.taxCalculationWithLeasing.healthInsuranceAmount)}}</td>
                  </tr>
                  <tr>
                    <td>{{$t('message.calculatedPflegeversicherung')}}</td>
                    <td
                      class="text-end negative-number">{{formatToEuro(resultData.taxCalculationWithoutLeasing.healthCareInsuranceAmount)}}</td>
                    <td
                      class="field text-end negative-number">{{formatToEuro(resultData.taxCalculationWithLeasing.healthCareInsuranceAmount)}}</td>
                  </tr>
                  <tr>
                    <td>{{$t('message.calculatedRentenversicherung')}}</td>
                    <td
                      class="text-end negative-number">{{formatToEuro(resultData.taxCalculationWithoutLeasing.pensionInsuranceAmount)}}</td>
                    <td
                      class="field text-end negative-number">{{formatToEuro(resultData.taxCalculationWithLeasing.pensionInsuranceAmount)}}</td>
                  </tr>
                  <tr>
                    <td>{{$t('message.calculatedAeitslosenversicherung')}}</td>
                    <td
                      class="text-end negative-number">{{formatToEuro(resultData.taxCalculationWithoutLeasing.unemploymentInsuranceAmount)}}</td>
                    <td
                      class="field text-end negative-number">{{formatToEuro(resultData.taxCalculationWithLeasing.unemploymentInsuranceAmount)}}</td>
                  </tr>

                  <tr class="break">
                    <td>{{$t('message.comparisonNetIncome')}}</td>
                    <td
                      class="text-end">{{formatToEuro(resultData.taxCalculationWithoutLeasing.netIncome)}}</td>
                    <td
                      class="field text-end">{{formatToEuro(resultData.taxCalculationWithLeasing.netIncome)}}</td>
                  </tr>
                  <tr>
                    <td>{{$t('message.calculatedGeldwerterVorteilAbzug')}}</td>
                    <td class="text-end" />
                    <td
                      class="field text-end negative-number">{{formatToEuro(resultData.taxCalculationWithLeasing.geldwerterVorteil)}}</td>
                  </tr>

                  <tr v-if="formData.healthcareType !== HEALTHCARE_TYPE.PUBLIC">
                    <td>{{$t('message.contributionToPrivateHealthCareInsurance')}}</td>
                    <td
                      class="text-end negative-number">{{formatToEuro(calculateInsuranceContribution(resultData))}}</td>
                    <td
                      class="field text-end negative-number">{{formatToEuro(calculateInsuranceContribution(resultData))}}</td>
                  </tr>

                  <tr class="break-grey" v-if="formData.typeOfWork === TYPE_OF_WORK.COLLECTIVE_EMPLOYEE">
                    <td>{{$t('message.result.special.festo.zNettoabzug')}}</td>
                    <td
                      class="text-end"
                    ></td>
                    <td
                      class="field text-end negative-number">{{formatToEuro(calculateAdditionalNetCosts(resultData))}}</td>
                  </tr>
                  <tr class="bold">
                    <td>{{$t('message.calculatedAuszahlungsbetrag')}}</td>
                    <td
                      class="text-end">{{formatToEuro(resultData.taxCalculationWithoutLeasing.payoutAmount)}}</td>
                    <td
                      class="field text-end">{{formatToEuro(resultData.taxCalculationWithLeasing.payoutAmount)}}</td>
                  </tr>
                  <tr class="bold">
                    <td>{{$t('message.quickOverviewTd4')}}</td>
                    <td class="text-end" />
                    <td
                      class="field text-end">{{formatToEuro(resultData.taxCalculationWithLeasing.netCharge)}}</td>
                  </tr>
                </table>
              </div>
            </div>

            <strong
              class="accordion-head collapsed d-flex"
              role="button"
              :class="{ active: comparePurchaseTableActive }"
              @click="comparePurchaseTableActive = !comparePurchaseTableActive"
            >{{ $t("message.result.special.festo.VergleichEigenkauf") }}<span class="pull-right" /></strong>

            <div
              class="accordion-body"
              :class="{ active: comparePurchaseTableActive }"
            >
              <div class="responsive-table">
                <table class="table compare-purchase">
                  <tr>
                    <td>
                      {{ $t('message.totalsTotalLeasingRates') }}
                      <span>{{ formData.leasingTime }} {{ $t('message.month') }}</span>
<!--                      {{ $t("message.result.special.festo.nurNet") }}-->
                    </td>
                    <td class="text-end" />
                    <td
                      class="field text-end bold">{{formatToEuro(resultData.totalCosts.totalLeasingRates)}}</td>
                  </tr>
                  <tr v-if="formData.typeOfWork===1">
                    <td>{{ $t("message.result.special.festo.VerwendeteFestoLeistungszulage") }}</td>
                    <td class="text-end" />
                    <td
                      class="field text-end bold">{{formatToEuro(resultData.totalCosts.bonusUsage.total * formData.leasingTime)}}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("message.result.special.festo.durchMitarbeiter") }}</td>
                    <td
                      class="text-end">{{formatToEuro(resultData.bikeCosts.bikePrice)}}</td>
                    <td
                      class="field text-end">{{formatToEuro(resultData.bikeCosts.remainingBikeValue)}}</td>
                  </tr>
                  <tr>
                    <td>
                      {{ $t('message.totalsTotalCostOfLeasing') }}
                      <span>{{ formData.leasingTime }} {{ $t('message.month') }}</span>
                    </td>
                    <td
                      class="text-end">{{formatToEuro(resultData.totalCosts.totalCostOfPurchase)}}</td>
                    <td
                      class="field text-end">{{formatToEuro(resultData.totalCosts.totalCostOfLeasing)}}</td>
                  </tr>
                  <tr class="break-grey">
                    <td>{{$t('message.savingsAbsolute')}}</td>
                    <td
                      class="text-end">{{formatToPercent(resultData.savings.percentageSavings)}}</td>
                    <td
                      class="field text-end">{{formatToEuro(resultData.savings.totalSavings)}}</td>
                  </tr>
                </table>
              </div>
            </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent, PropType, toRaw } from "vue";
import { Api } from "@/services/api-service";
import { ValidLocales } from "@/enums/Application";
import { HEALTHCARE_TYPE, IFormValues, TYPE_OF_WORK } from "@/store/interfaces/IFormValues";
import { IApiResultCollectiveAgreement } from "@/interfaces/api-result.collective-agreement";

export default defineComponent({
	name: 'ResultViewFesto',
    computed: {
      TYPE_OF_WORK() {
        return TYPE_OF_WORK
      },
        HEALTHCARE_TYPE() {
            return HEALTHCARE_TYPE
        }
    },
	props: {
    formData: { type: Object as PropType<IFormValues>, default: null },
		resultData: { type: Object as PropType<IApiResultCollectiveAgreement>, default: null },
	},
	data() {
		return {
			result: toRaw(this.resultData),
			leasingTableActive: false,
			comparePurchaseTableActive: false,
		}
	},
	methods: {
		formatToEuro(val = 0, isNegative = false): string {
      let retVal = '0,00 €';
      if(val) {
        retVal = val.toLocaleString(ValidLocales.GERMANY, {
          style: 'currency',
          currency: 'EUR',
          minimumFractionDigits: 2
        });
      }
      return `${(isNegative && val > 0 ? '-': '')}${retVal}`;
		},
		formatToPercent(val: number): string {
			if(val)
				return val.toFixed(0) + "%";
			else
				return '0 %';
		},
    calculateInsuranceContribution(resultData: IApiResultCollectiveAgreement) {
      const calculationResult = resultData.taxCalculationWithLeasing.sumPkvPv;
      switch (this.formData.healthcareType) {
        case HEALTHCARE_TYPE.PRIVATE_WITH_CONTRIBUTION:
          return calculationResult / 2;
        case HEALTHCARE_TYPE.PRIVATE_WITHOUT_CONTRIBUTION:
          return calculationResult;
        case HEALTHCARE_TYPE.PUBLIC:
          return 0;
      }
    },
		calculateLeasingTotalValue(data: IApiResultCollectiveAgreement): string {
			const employeeResult = data.monthlyCosts.employeeCosts;
			return this.formatToEuro(employeeResult.monthlyLeasingRate +
					employeeResult.insuranceComfort +
					employeeResult.insuranceWearAndTear +
					employeeResult.inspection);
		},
		myFilter(): void {
			this.leasingTableActive = !this.leasingTableActive;
			this.comparePurchaseTableActive = !this.comparePurchaseTableActive;
		},
    calculateAdditionalNetCosts(data: IApiResultCollectiveAgreement): number {
      if (data.taxCalculationWithLeasing.furtherNetCosts){
        return data.taxCalculationWithLeasing.furtherNetCosts;
      }
      return data.taxCalculationWithLeasing.netIncome - data.taxCalculationWithLeasing.geldwerterVorteil - data.taxCalculationWithLeasing.payoutAmount;
    }
	}
})

</script>

<style lang="scss">
@import "@/styles/elements/results.scss";
</style>
