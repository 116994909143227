import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SelectComponent = _resolveComponent("SelectComponent")!
  const _component_InputToggleComponent = _resolveComponent("InputToggleComponent")!
  const _component_InputRangeComponent = _resolveComponent("InputRangeComponent")!
  const _component_InputComponent = _resolveComponent("InputComponent")!
  const _component_NumberComponent = _resolveComponent("NumberComponent")!

  return (_ctx.showField)
    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
        (_ctx.fieldData['type'] === 'select')
          ? (_openBlock(), _createBlock(_component_SelectComponent, {
              key: 0,
              options: _ctx.fieldData['options'],
              label: _ctx.fieldData['label'][_ctx.$i18n.locale],
              "field-id": _ctx.fieldData['id'],
              tooltip: _ctx.fieldData['tooltip'],
              type: _ctx.fieldData['type'],
              "default-val": _ctx.fieldData['defaultVal'],
              required: _ctx.fieldData['required'],
              isExpanded: _ctx.fieldData['isExpanded']
            }, null, 8 /* PROPS */, ["options", "label", "field-id", "tooltip", "type", "default-val", "required", "isExpanded"]))
          : _createCommentVNode("v-if", true),
        (_ctx.fieldData['type'] === 'toggle')
          ? (_openBlock(), _createBlock(_component_InputToggleComponent, {
              key: 1,
              modelValue: _ctx.toggleValue,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.toggleValue) = $event)),
              "input-name": "radioName123",
              "status-on": _ctx.fieldData['statusOn'],
              "status-off": _ctx.fieldData['statusOff'],
              label: _ctx.fieldData['label'][_ctx.$i18n.locale],
              "field-id": _ctx.fieldData['id'],
              tooltip: _ctx.fieldData['tooltip'],
              type: _ctx.fieldData['type'],
              isExpanded: _ctx.fieldData['isExpanded']
            }, null, 8 /* PROPS */, ["modelValue", "status-on", "status-off", "label", "field-id", "tooltip", "type", "isExpanded"]))
          : _createCommentVNode("v-if", true),
        (_ctx.fieldData['type'] === 'range')
          ? (_openBlock(), _createBlock(_component_InputRangeComponent, {
              key: 2,
              "default-value": _ctx.fieldData['defaultValue'],
              min: _ctx.fieldData['min'],
              max: _ctx.fieldData['max'],
              label: _ctx.fieldData['label'][_ctx.$i18n.locale],
              "field-id": _ctx.fieldData['id'],
              tooltip: _ctx.fieldData['tooltip'],
              step: _ctx.fieldData['step'],
              type: _ctx.fieldData['type'],
              format: _ctx.fieldData['format'],
              isExpanded: _ctx.fieldData['isExpanded'],
              style: _normalizeStyle(_ctx.fieldData['style'])
            }, null, 8 /* PROPS */, ["default-value", "min", "max", "label", "field-id", "tooltip", "step", "type", "format", "isExpanded", "style"]))
          : _createCommentVNode("v-if", true),
        (_ctx.fieldData['type'] === 'text')
          ? (_openBlock(), _createBlock(_component_InputComponent, {
              key: 3,
              placeholder: _ctx.fieldData['label'],
              label: _ctx.fieldData['label'][_ctx.$i18n.locale],
              "field-id": _ctx.fieldData['id'],
              tooltip: _ctx.fieldData['tooltip'],
              type: _ctx.fieldData['type'],
              isExpanded: _ctx.fieldData['isExpanded']
            }, null, 8 /* PROPS */, ["placeholder", "label", "field-id", "tooltip", "type", "isExpanded"]))
          : _createCommentVNode("v-if", true),
        (_ctx.fieldData['type'] === 'number')
          ? (_openBlock(), _createBlock(_component_NumberComponent, {
              key: 4,
              label: _ctx.fieldData['label'][_ctx.$i18n.locale],
              "field-id": _ctx.fieldData['id'],
              tooltip: _ctx.fieldData['tooltip'],
              type: _ctx.fieldData['type'],
              isExpanded: _ctx.fieldData['isExpanded']
            }, null, 8 /* PROPS */, ["label", "field-id", "tooltip", "type", "isExpanded"]))
          : _createCommentVNode("v-if", true),
        (['toggle', 'select'].includes(_ctx.fieldData['type']) && _ctx.fieldData['subfields'])
          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 5 }, _renderList(_ctx.fieldData['subfields'], (subfield) => {
              return (_openBlock(), _createElementBlock(_Fragment, {
                key: subfield.id
              }, [
                ((subfield['type'] === 'range'  && subfield['dispcon'] === _ctx.toggleValue) || (subfield['type'] === 'range' && subfield['dispcon'] === _ctx.$store.state.formValues[_ctx.fieldData['id']]))
                  ? (_openBlock(), _createBlock(_component_InputRangeComponent, {
                      key: 0,
                      "default-value": subfield['defaultValue'],
                      min: subfield['min'],
                      max: subfield['max'],
                      label: subfield['label'][_ctx.$i18n.locale],
                      "field-id": subfield['id'],
                      tooltip: subfield['tooltip'],
                      step: subfield['step'],
                      type: subfield['type'],
                      format: subfield['format'],
                      style: {"margin-left":"1.5em"},
                      isExpanded: subfield['isExpanded']
                    }, null, 8 /* PROPS */, ["default-value", "min", "max", "label", "field-id", "tooltip", "step", "type", "format", "isExpanded"]))
                  : _createCommentVNode("v-if", true),
                (subfield['type'] === 'toggle' && subfield['dispcon'] === _ctx.toggleValue)
                  ? (_openBlock(), _createBlock(_component_InputToggleComponent, {
                      key: 1,
                      modelValue: _ctx.toggleValueSubfield,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.toggleValueSubfield) = $event)),
                      "input-name": "radioName123",
                      "status-on": subfield['statusOn'],
                      "status-off": subfield['statusOff'],
                      label: subfield['label'][_ctx.$i18n.locale],
                      "field-id": subfield['id'],
                      tooltip: subfield['tooltip'],
                      type: subfield['type'],
                      style: {"margin-left":"1.5em"},
                      isExpanded: subfield['isExpanded']
                    }, null, 8 /* PROPS */, ["modelValue", "status-on", "status-off", "label", "field-id", "tooltip", "type", "isExpanded"]))
                  : _createCommentVNode("v-if", true)
              ], 64 /* STABLE_FRAGMENT */))
            }), 128 /* KEYED_FRAGMENT */))
          : _createCommentVNode("v-if", true)
      ], 64 /* STABLE_FRAGMENT */))
    : _createCommentVNode("v-if", true)
}