<template>
	<Popper :arrow="true" :show="showPopper">
		<div v-if="langswitch" style="margin-right: 0.5em;">
			<CountryFlag id="lang-flag-icon" style="cursor: pointer;" :country="countryFlag" size='normal' @click="showPopper = true" />
		</div>
		<template #content>
			<div>
				<span v-for="(flag, idx) in availableFlagsFiltered" :key="idx" @click="switchLocale(flag)" style="cursor: pointer;padding-right: 0.4em">
					<CountryFlag :country="flag.toLowerCase()" size='normal'/>
				</span>
			</div>
		</template>
	</Popper>
</template>

<style>
  :root {
    --popper-theme-background-color: #fff;
    --popper-theme-background-color-hover: #fff;
    --popper-theme-text-color: #000;
    --popper-theme-border-width: 0px;
    --popper-theme-border-style: solid;
    --popper-theme-border-radius: 6px;
    --popper-theme-padding: 5px;
    --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
  }
</style>

<script lang="ts">
import { defineComponent } from "vue";
import CountryFlag from "vue-country-flag-next";
import Popper from "vue3-popper";
import "regenerator-runtime/runtime";
import { VALID_COUNTRIES, ValidLocales, VALID_LOCALES_EXTENTION } from "@/enums/Application";
import { useUrlParams} from "@/composables/useRoutingParameters";

export default defineComponent({
	name: 'LanguageSwitch',
	components: {
		CountryFlag,
		Popper,
	},
	data() {
      return {
        showPopper: false,
        availableFlags: [VALID_LOCALES_EXTENTION.GB, VALID_COUNTRIES.GERMANY, VALID_COUNTRIES.AUSTRIA]
      };
    },
	props: {
		langswitch: {type: String, default: 'de-DE' }
	},
	computed: {
		lang: {
			get(): string {
				return this.$store.state.appData.lang;
			},
			set(lang: string) {
				this.$store.commit('setLang', lang);
			}
		},
    countryFlag: {
      get(): string {
        let flag;
        switch (this.$i18n.locale) {
          case ValidLocales.GERMANY:
            flag = VALID_COUNTRIES.GERMANY.toLowerCase();
            break;
            case ValidLocales.AUSTRIA:
              flag = VALID_COUNTRIES.AUSTRIA.toLowerCase();
              break;
            case ValidLocales.USA:
              flag = VALID_LOCALES_EXTENTION.GB.toLowerCase();
              break;
        }
        return flag;
      },
      set(countryFlag: string) {
        console.log("Changed countryFlag to: " + countryFlag);
      }
    },
    availableFlagsFiltered(): string[] {
      const country = this.$store.state.appData.country;

      if (country === VALID_COUNTRIES.GERMANY) {
        return this.availableFlags.filter((flag) => flag.toLowerCase() !== VALID_COUNTRIES.AUSTRIA.toLowerCase() && flag.toLowerCase() !== this.countryFlag.toLowerCase());
      } else if (country === VALID_COUNTRIES.AUSTRIA) {
        return this.availableFlags.filter((flag) => flag.toLowerCase() !== VALID_COUNTRIES.GERMANY.toLowerCase() && flag.toLowerCase() !== this.countryFlag.toLowerCase());
      } else {
        return this.availableFlags;
      }
    },
		country: {
			get(): string {
				return this.$store.state.appData.country;
			},
			set(country: string) {
				if(country === '')
					country = 'DE';
				this.$store.commit('setCountry', country);
			}
		},
	},
	mounted() {
    const { getUrlParam } = useUrlParams();
		const lang = getUrlParam('lang');
		const country = getUrlParam('country');
		const availableLocales = this.$i18n.availableLocales;
		const fallbackLocale = this.$i18n.fallbackLocale;
		const localeFound = availableLocales?.find((element) => element === lang);
		this.$i18n.locale = (lang && localeFound ? lang.toString() : fallbackLocale?.toString());
		this.lang = lang ?? ValidLocales.GERMANY;
		this.country = country ?? VALID_COUNTRIES.GERMANY;
	},
	methods: {
		switchLocale(flag: string) {
      if (this.countryFlag !== flag) {
        switch (flag) {
          case VALID_COUNTRIES.GERMANY:
            this.$i18n.locale = ValidLocales.GERMANY;
            break;
          case VALID_COUNTRIES.AUSTRIA:
            this.$i18n.locale = ValidLocales.AUSTRIA;
            break;
          case VALID_LOCALES_EXTENTION.GB:
            this.$i18n.locale = ValidLocales.USA;
            break;
        }
			}
			this.lang = flag;
			this.showPopper = false;
		},		
	},
  watch: {
    'this.$store.appData.lang'(newVal) {
      this.$i18n.locale = newVal;
      this.lang = newVal;
    },
    'this.$store.appData.country'(newVal) {
      this.country = newVal;
    },
  },
})
</script>