import { IEmployer } from "@/models/IEmployer";
import { BIKE_TYPES, HEALTHCARE_TYPE, IFormValues } from "@/store/interfaces/IFormValues";
import { State } from "@/store/store";
import { GERMAN_STATES, LEASING_TIME, STANDARD_BIKE_PRICES } from "@/enums/CalculatorConstants";
import { EMPLOYMENT_ROLE_BOOL, mapToggleValueToEmploymentRole } from "@/mapper/employment-role.mapper";
import { IApiPayloadEmployee } from "@/interfaces/api-payload.employee";
import { IApiPayloadEmployeeAustria } from "@/interfaces/api-payload.employee-austria";

export function mapFormPayload(data: IFormValues, employer: IEmployer = null, state: State = null): IApiPayloadEmployee {
    const churchTax = (!(!data.employeechurchtax || data.employeechurchtax === 'No'));
    const leasingTime = data.leasingTime ?? employer?.leasingTime ?? LEASING_TIME.MONTHS_36;
    const result = {
        bikeData: {
            bikePrice: data.bikePrice,
            recommendedPurchasePrice: data.recommendedPurchasePrice ?? data.bikePrice,
            bikeType: data.bikeType ?? BIKE_TYPES.PEDELEC, // TODO: Add this in the REACT App, currently not used
            maximumNetCharge: data.maximumNetCharge ?? 0,
            leasingTime: leasingTime,
            distanceToWorkplace: 0 // TODO: Add this in the REACT App, currently not used. Should only be asked, if BIKE_TYPE is S-PEDELEC
        },
        employeeData: {
            bonus: data.bonus ?? 0, // TODO: Check the name from the data
            children: data.children ?? -1,
            churchTax: churchTax,
            healthcareSurcharge: data.healthcareSurcharge ?? 0,
            monthlyIncome: data.monthlyIncome ?? 3000,
            extraExpenses: data.extraExpenses ?? 0,
            state: data.employeedistrict as GERMAN_STATES ?? GERMAN_STATES.NIEDERSACHSEN,
            healthcareType: data.healthcareType ?? HEALTHCARE_TYPE.PUBLIC,
            pensionTax: data.pensionTax,
            taxClass: data.taxClass ?? 1,
            factorTaxClassFour: 0, // TODO: Add this in the REACT App, currently not used
            privateCareInsuranceContribution: data.privateCareInsuranceContribution ?? 0,
            privateHealthInsuranceContribution: data.privateHealthInsuranceContribution ?? 0,
        },
        employerData: {
            companyCode: data.companyCode ?? employer?.companyCode  ?? '',
            agavIncludedInLeasingrate: employer?.agavIncludedInLeasingrate ?? true,
            employerTaxDeduction: data.employerTaxDeduction ?? employer?.employerTaxDeduction ?? true,
            leasingFactor36months: employer?.leasingFactor36months ?? null,
            leasingFactor48months: employer?.leasingFactor48months ?? null,
            leasingShareEmployer: data.leasingShareEmployer ?? employer?.leasingShareEmployer ?? 0,
            leasingShareEmployerPercentage: data.leasingShareEmployerPercentage ?? employer?.leasingShareEmployerPercentage ?? 0,
            minBikePrice: (state && state.formDefaults.get("bikePrice").minValue) ?? STANDARD_BIKE_PRICES.MIN,
            maxBikePrice: (state && state.formDefaults.get("bikePrice").maxValue) ?? STANDARD_BIKE_PRICES.MAX,
        },
        bikeInsuranceData: {
            comfort: data.comfort ?? true,
            comfortPaidBy: EMPLOYMENT_ROLE_BOOL[data.comfortPaidBy],
            inspection: data.inspection,
            inspectionPaidBy: EMPLOYMENT_ROLE_BOOL[data.inspectionPaidBy],
            wearAndTear: data.wearAndTear,
            wearAndTearPaidBy: EMPLOYMENT_ROLE_BOOL[data.wearAndTearPaidBy],
        }
    }
    return result;
}

export function mapFormPayloadAustria(data: IFormValues, employer: IEmployer = null, state: State = null): IApiPayloadEmployeeAustria {
    return {
        bikeData: {
            bikePrice: data.bikePrice,
            maximumNetCharge: data.maximumNetCharge ?? 0,
            recommendedPurchasePrice: data.recommendedPurchasePrice,
            leasingTime: data.leasingTime ?? LEASING_TIME.MONTHS_48,
        },
        employerData: {
            companyCode: data.companyCode ?? employer?.companyCode  ?? '',
            agavIncludedInLeasingrate: employer?.agavIncludedInLeasingrate ?? true,
            employerTaxDeduction: data.employerTaxDeduction ?? employer?.employerTaxDeduction ?? true,
            leasingFactor36months: employer?.leasingFactor36months ?? null,
            leasingFactor48months: employer?.leasingFactor48months ?? null,
            leasingShareEmployer: data.leasingShareEmployer ?? employer?.leasingShareEmployer ?? 0,
            leasingShareEmployerPercentage: data.leasingShareEmployerPercentage ?? employer?.leasingShareEmployerPercentage ?? 0,
            minBikePrice: (state && state.formDefaults.get("bikePrice").minValue) ?? STANDARD_BIKE_PRICES.MIN,
            maxBikePrice: (state && state.formDefaults.get("bikePrice").maxValue) ?? STANDARD_BIKE_PRICES.MAX,
            at16: !!employer?.at16,
            updatedCalculation2023: data.updatedCalculation2023,
        },
        employeeData: {
            extraExpense: data.extraExpenses ?? 0,
            monthlyBonus: data.bonus ?? 0,
            monthlyIncome: data.monthlyIncome,
        },
        bikeInsuranceData: {
            comfort: data.comfort ?? true,
            comfortPaidBy: mapToggleValueToEmploymentRole(data.comfortPaidBy),
            inspection: data.inspection,
            inspectionPaidBy: mapToggleValueToEmploymentRole(data.inspectionPaidBy),
            wearAndTear : data.wearAndTear,
            wearAndTearPaidBy: mapToggleValueToEmploymentRole(data.wearAndTearPaidBy),
        }
    }
}

