import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  type: "button",
  class: "btn"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", _hoisted_1, [
    _renderSlot(_ctx.$slots, "before"),
    _createTextVNode(" " + _toDisplayString(_ctx.text) + " ", 1 /* TEXT */),
    _renderSlot(_ctx.$slots, "after")
  ]))
}