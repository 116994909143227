import { FormMode } from "@/enums/Application";
import { State } from "@/store/store";
import Swal from "sweetalert2";
import { ComposerTranslation } from "vue-i18n";
import { TYPE_OF_WORK } from "@/store/interfaces/IFormValues";

export const validateform = (state: State, t: ComposerTranslation) => {

    // List price is greater than total price
    if([
        FormMode.EMPLOYEE, 
        FormMode.EMPLOYEE_AT, 
        FormMode.OFFICIAL, 
        FormMode.COLLECTIVE_AGREEMENT
    ].some(f => f === state.appData.formType) && state.formValues.recommendedPurchasePrice < state.formValues.bikePrice) {
        Swal.fire({
            title: t('message.incorrectPurchasePrice'),
            text: t('message.incorrectPurchasePriceDetails'),
            icon: "error",
            buttonsStyling: false,
            customClass: {
                confirmButton: "btn-swal-error",
                cancelButton: "btn-swal-error",
            },
            confirmButtonText: t('message.correctInput')
        });
        return false;
    }
    // collectiveagreement typeOfWork not set
    if([FormMode.COLLECTIVE_AGREEMENT].some(f => f === state.appData.formType) && state.formValues.typeOfWork === TYPE_OF_WORK.UNSET) {
        Swal.fire({
            title: t('message.validate.typeOfWork.title'),
            text: t('message.validate.typeOfWork.text'),
            icon: "error",
            buttonsStyling: false,
            customClass: {
                confirmButton: "btn-swal-error",
                cancelButton: "btn-swal-error",
            },
            confirmButtonText: t('message.correctInput')
        });
        return false;
    }

    return true;
};
