import { EMPLOYMENT_ROLE_ENUM, IEmployer } from "@/models/IEmployer";
import {HEALTHCARE_TYPE, IFormValues} from "./interfaces/IFormValues";
import {SliderDefaults} from "./store";
import {StandardColors, VALID_COUNTRIES} from "@/enums/Application";
import {STANDARD_BIKE_PRICES} from "@/enums/CalculatorConstants";

export const defaultValues: Map<string, SliderDefaults> = new Map<string, SliderDefaults>([
    ["bikePrice", {minValue: STANDARD_BIKE_PRICES.MIN, maxValue: STANDARD_BIKE_PRICES.MAX}],
    ["recommendedPurchasePrice", {minValue: STANDARD_BIKE_PRICES.MIN, maxValue: STANDARD_BIKE_PRICES.MAX}],
    ["maximumNetCharge", {minValue: 0, maxValue: 250}],
    ["comfort", {disabled: true}],
    ["leasingTime", {disabled: false}]
]);

export const defaultForm: IFormValues = {
    bikeAmount: 1,
    bikePrice: 649,
    bonus: 0,
    children: -1,
    comfort: true,
    comfortPaidBy: 1, // 1 = EMPLOYER, 0 = EMPLOYEE
    companyCode: null,
    employeechurchtax: true,
    employeedistrict: "NI",
    employerTaxDeduction: true,
    extraExpenses: 0,
    firstBike: true,
    formMode: true,
    healthcareSurcharge: 0,
    healthcareType: HEALTHCARE_TYPE.PUBLIC,
    inspection: true,
    inspectionPaidBy: 0, // 1 = EMPLOYER, 0 = EMPLOYEE
    leasingShareEmployer: 0,
    leasingShareEmployerPercentage: 0,
    leasingTime: 36,
    maximumNetCharge: 0,
    mode: false,
    monthlyIncome: 3000,
    pensionTax: true,
    privateCareInsuranceContribution: 0,
    privateHealthInsuranceContribution: 0,
    recommendedPurchasePrice: 649,
    taxClass: 1,
    typeOfWork: -1,
    updatedCalculation2023: true,
    wearAndTear: true,
    wearAndTearPaidBy: 0, // 1 = EMPLOYER, 0 = EMPLOYEE
}

export const defaultEmployer: IEmployer = {
    agavIncludedInLeasingrate: true,
    agavLegalProtectionLiabilityMandatory: false,
    agavLegalProtectionLiabilityPaidBy: EMPLOYMENT_ROLE_ENUM.EMPLOYEE,
    agavPremiumMandatory: false,
    agavPremiumPaidBy: EMPLOYMENT_ROLE_ENUM.EMPLOYEE,
    agavPremiumPlusMandatory: false,
    agavPremiumPlusPaidBy: EMPLOYMENT_ROLE_ENUM.EMPLOYEE,
    at16: false,
    comfort: true,
    comfortPaidBy: EMPLOYMENT_ROLE_ENUM.EMPLOYEE,
    company: "---",
    companyCode: null,
    country: VALID_COUNTRIES.GERMANY,
    employerTaxDeduction: null,
    inspection: undefined, // Keep null or undefined to give the user to choice to select it
    inspectionNotAllowed: false,
    inspectionPaidBy: EMPLOYMENT_ROLE_ENUM.EMPLOYEE,
    leasingBank: "",
    leasingFactor36months: null,
    leasingFactor48months: null,
    leasingShareEmployer: 0,
    leasingShareEmployerPercentage: 0,
    leasingTime: 36,
    maxBikePrice: 15000,
    minBikePrice: 649,
    primaryColor: StandardColors.PRIMARY,
    secondaryColor: StandardColors.SECONDARY,
    wearAndTear: undefined, // Keep null or undefined to give the user to choice to select it
    wearAndTearNotAllowed: false,
    wearAndTearPaidBy: EMPLOYMENT_ROLE_ENUM.EMPLOYEE
}
