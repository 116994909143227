import { createI18n } from 'vue-i18n';
import { ValidLocales } from './enums/Application'
import { de_AT } from './lang/de-AT';
import { de_DE } from './lang/de-DE';
import { en_US } from './lang/en-US';

type MessageSchema = typeof de_DE

export const i18n = createI18n<[MessageSchema], ValidLocales>({
  locale: 'de-DE',
  messages: {
    'en-US': en_US,
    'de-DE': de_DE,
    'de-AT': de_AT,
  },
  fallbackLocale: 'de-DE',
  silentTranslationWarn: true,
  silentFallbackWarn: true,
  globalInjection: true,
  legacy: false,
  fallbackWarn: false,
  missingWarn: false,
});
