import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  style: {"margin-right":"0.5em"}
}
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CountryFlag = _resolveComponent("CountryFlag")!
  const _component_Popper = _resolveComponent("Popper")!

  return (_openBlock(), _createBlock(_component_Popper, {
    arrow: true,
    show: _ctx.showPopper
  }, {
    content: _withCtx(() => [
      _createElementVNode("div", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availableFlagsFiltered, (flag, idx) => {
          return (_openBlock(), _createElementBlock("span", {
            key: idx,
            onClick: ($event: any) => (_ctx.switchLocale(flag)),
            style: {"cursor":"pointer","padding-right":"0.4em"}
          }, [
            _createVNode(_component_CountryFlag, {
              country: flag.toLowerCase(),
              size: "normal"
            }, null, 8 /* PROPS */, ["country"])
          ], 8 /* PROPS */, _hoisted_2))
        }), 128 /* KEYED_FRAGMENT */))
      ])
    ]),
    default: _withCtx(() => [
      (_ctx.langswitch)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_CountryFlag, {
              id: "lang-flag-icon",
              style: {"cursor":"pointer"},
              country: _ctx.countryFlag,
              size: "normal",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showPopper = true))
            }, null, 8 /* PROPS */, ["country"])
          ]))
        : _createCommentVNode("v-if", true)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["show"]))
}