import { VALID_COUNTRIES } from "@/enums/Application";

/**
 * Enum for the two main employment roles:
 *
 * - `EMPLOYEE` = Arbeitnehmer (En: Employee)
 * - `EMPLOYER` = Arbeitgeber (En: Employer)
 */
export enum EMPLOYMENT_ROLE_ENUM {
  EMPLOYEE = "AN",
  EMPLOYER = "AG"
}

export type LeasingTime = 36 | 48;

export interface IEmployer {
  agavIncludedInLeasingrate: boolean;
  agavLegalProtectionLiabilityMandatory: boolean | null;
  agavLegalProtectionLiabilityPaidBy: EMPLOYMENT_ROLE_ENUM;
  agavPremiumMandatory: boolean;
  agavPremiumPaidBy: EMPLOYMENT_ROLE_ENUM;
  agavPremiumPlusMandatory: boolean;
  agavPremiumPlusPaidBy: EMPLOYMENT_ROLE_ENUM;
  at16: boolean;
  company: string;
  companyCode: string;
  country: VALID_COUNTRIES;
  leasingShareEmployer: number;
  leasingShareEmployerPercentage: number;
  leasingTime: LeasingTime;
  maxBikePrice: number;
  minBikePrice: number;
  employerTaxDeduction: boolean;
  inspection: boolean;
  inspectionNotAllowed: boolean;
  comfort: boolean;
  wearAndTearNotAllowed: boolean;
  wearAndTear: boolean;
  wearAndTearPaidBy: EMPLOYMENT_ROLE_ENUM;
  leasingBank: string;
  leasingFactor36months: number | null;
  leasingFactor48months: number | null;
  primaryColor: string;
  secondaryColor: string;
  inspectionPaidBy: EMPLOYMENT_ROLE_ENUM;
  comfortPaidBy: EMPLOYMENT_ROLE_ENUM;
  updatedCalculation2023?: boolean;
}
