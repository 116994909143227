<template>
<fieldset :ref="fieldId" :class="'form-floating mt-3 field-is-' + type" v-if="validateMode()">
  <div class="form-floating">
    <input
      :id="'input-' + id"
      v-model="value"
      class="form-control number-input"
      type="number"
      min="0"
      :name="'input-' + id"
      @change="change()"
    >
    <LabelComponent
      v-if="label"
      :label="label.toString()"
      :label-for="'input-' + id"
    />
  </div>
</fieldset>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import LabelComponent from '@/components/form/LabelComponent.vue'
import { FormPayload } from '@/store/store';
import emitter from "@/services/emitter";

export default defineComponent({
  name: 'NumberComponent',
  components: {
    LabelComponent
  },
  props: {
    label: { type: String, required: true },
    fieldId: { type: String, required: true },
    type: { type: String, required: true },
    required: { type: Boolean },
    isExpanded: { type: Boolean, default: false }
  },
  emits: ['change'],
  computed: {
		value: {
			get(): boolean {
				return this.$store.state.formValues[this.fieldId];
			},
			set(value: boolean) {
				this.$store.state.formValues[this.fieldId] = value;
        emitter.emit("refreshStore");
			}
		},
    expandedMode() {
      return this.$store.state.formValues.formMode
    }
  },
  data() {
    return {
      id: Math.ceil(Math.random()*1000000),
    }
  },
  methods: {
    change() {
      this.$emit('change', this.value, `input${this.id}`);
      this.$store.commit('setFormValue', {key: this.fieldId, value: this.value} as FormPayload);
    },
    validateMode() {
      return (this.isExpanded && !this.expandedMode ? false : true);
    }
  }
})
</script>

<style lang="scss">
@import "@/styles/elements/form/_all.scss";
</style>