// Types and interfaces
import { IEmployer } from "@/models/IEmployer";
import {
  IFormValues,
  IFormValuesSelfEmployed,
} from "@/store/interfaces/IFormValues";
import Axios, { AxiosResponse } from "axios";

// Constants
import { defaultEmployer } from "@/store/defaults";
import { FormMode, VALID_COUNTRIES } from "@/enums/Application";

// Helpers
import {
  mapFormPayload,
  mapFormPayloadAustria,
} from "@/mapper/form-payload-mapper";

// Tools
import { ApiFormJsonPayload } from "@/interfaces/api-payloads";
import { State } from "@/store/store";
import { checkCompanySpecificColors } from "@/helper/coloring-helper";
import { IAbstractApiResult } from "@/interfaces/abstract-api-payload";
import { IApiResult } from "@/interfaces/api-result.employee";
import { mapFormPayloadSelfEmployed } from "@/mapper/form-payload-self-employed";
import { IApiResultSelfEmployed } from "@/interfaces/api-result.self-employed";
import { IApiResultAustria } from "@/interfaces/api-result.employee-austria";
import { IApiPayloadCollectveAgreement } from "@/interfaces/api-payload-collective-agreement";
import { mapFormPayloadCollectiveAgreement } from "@/mapper/form-payload-mapper-collective-agreement";
import { IApiResultCollectiveAgreement } from "@/interfaces/api-result.collective-agreement";
import { IApiPayloadEmployee } from "@/interfaces/api-payload.employee";
import { IApiPayloadEmployeeAustria } from "@/interfaces/api-payload.employee-austria";

class ApiService {
  protected api_url = "https://calculator-backend.bikeleasing.de/api/";
  protected api_version = "v2";

  private employer: IEmployer = defaultEmployer;
  protected state: State;

  constructor() {
    this.init_service();
  }
  private formPayload: IApiPayloadEmployee = null;
  private formPayloadAt: IApiPayloadEmployeeAustria = null;
  private init_service() {
    if (process.env.VUE_APP_API_URL !== undefined)
      this.api_url = process.env.VUE_APP_API_URL;
    if (process.env.VUE_APP_API_VERSION !== undefined)
      this.api_version = process.env.VUE_APP_API_VERSION;

    Axios.defaults.headers.common["Cache-Control"] =
      "no-cache, no-store, must-revalidate";
    Axios.defaults.headers.common["Pragma"] = "no-cache";
    Axios.defaults.headers.common["Expires"] = "0";
  }

  public setEmployer(employer: IEmployer): void {
    this.employer = employer;
  }

  public setState(state: State): void {
    this.state = state;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore-next-line
  public async getCalculationResult(
    formMode: string,
    formData: IFormValues | IFormValuesSelfEmployed
  ): Promise<IAbstractApiResult<any, any, any, any, any, any>> {
    switch (formMode) {
      case FormMode.EMPLOYEE:
        return await this.getCalculationResultDeEmployee(
          formData as IFormValues,
          false
        );
      case FormMode.EMPLOYEE_AT:
        return await this.getCalculationResultAtEmployee(
          formData as IFormValues
        );
      case FormMode.OFFICIAL:
        return await this.getCalculationResultDeEmployee(
          formData as IFormValues,
          true
        );
      case FormMode.SELF_EMPLOYED:
        return await this.getCalculationResultSelfEmployed(
          formData,
          VALID_COUNTRIES.GERMANY
        );
      case FormMode.SELF_EMPLOYED_AT:
        return await this.getCalculationResultSelfEmployed(
          formData,
          VALID_COUNTRIES.AUSTRIA
        );
      case FormMode.COLLECTIVE_AGREEMENT:
        return await this.getCalculationResultCollectiveAgreement(
          formData as IFormValues
        );
      default:
        throw new Error("Form mode not supported");
    }
  }

  private async getCalculationResultDeEmployee(
    formData: IFormValues,
    isOfficial = false
  ): Promise<IApiResult> {
    const formPayload = mapFormPayload(
      formData as IFormValues,
      this.state.employer,
      this.state
    );
    this.formPayload = formPayload;
    let url = `${this.api_url}${this.api_version}/calculation`;
    if (isOfficial) {
      url += `/official/${VALID_COUNTRIES.GERMANY}`;
    } else {
      url += `/${VALID_COUNTRIES.GERMANY}`;
    }
    const result = await Axios.post(url, formPayload, {
      headers: {
        "Accept-Language": ""
      }
    }).catch((err) => {
      throw err;
    });
    return result.data as Promise<IApiResult>;
  }

  private async getCalculationResultAtEmployee(
    formData: IFormValues
  ): Promise<IApiResultAustria> {
    const formPayload = mapFormPayloadAustria(
      formData as IFormValues,
      this.state.employer,
      this.state
    );
    this.formPayloadAt = formPayload;
    const url = `${this.api_url}${this.api_version}/calculation/${VALID_COUNTRIES.AUSTRIA}`;

    const result = await Axios.post(url, formPayload,{
      headers: {
        "Accept-Language": ""
      }
    }).catch((err) => {
      throw err;
    });
    return result.data as Promise<IApiResultAustria>;
  }

  private async getCalculationResultSelfEmployed(
    formData: IFormValuesSelfEmployed,
    country: VALID_COUNTRIES
  ): Promise<IApiResultSelfEmployed> {
    const formPayload = mapFormPayloadSelfEmployed(
      formData as IFormValues,
      this.state.employer,
      this.state
    );
    const url = `${this.api_url}${this.api_version}/calculation/selfEmployed/${country}`;
    const result = await Axios.post(url, formPayload,{
      headers: {
        "Accept-Language": ""
      }
    }).catch((err) => {
      throw err;
    });
    return result.data as Promise<IApiResultSelfEmployed>;
  }

  private async getCalculationResultCollectiveAgreement(
    formData: IFormValues
  ): Promise<IApiResultCollectiveAgreement> {
    const formPayload = mapFormPayloadCollectiveAgreement(
      formData as IFormValues,
      this.state.employer,
      this.state
    ) as IApiPayloadCollectveAgreement;
    this.formPayload = formPayload;
    const url = `${this.api_url}${this.api_version}/calculation/collectiveagreement/${VALID_COUNTRIES.GERMANY}`;
    const result = await Axios.post(url, formPayload,{
      headers: {
        "Accept-Language": ""
      }
    }).catch((err) => {
      throw err;
    });
    return result.data as Promise<IApiResultCollectiveAgreement>;
  }
  public async getCompanyByCode(code: string): Promise<AxiosResponse | null> {
    if (code && code.length >= 4) {
      let response = await Axios.get(
        `${this.api_url}v1/employer?code=${code.toUpperCase()}`,
        { validateStatus: () => true }
      );

      if (response.status === 300) {
        // Invalid code entered
        return null;
      }

      if (response.status >= 400) {
        // Error handling
        console.error("Error while fetching employer data", response.data);
        return null;
      }

      response = checkCompanySpecificColors(response);

      return response;
    }
    return null;
  }

  public async getForm(
    type: string
  ): Promise<AxiosResponse<ApiFormJsonPayload>> {
    if (
      Array.from(Object.values(FormMode)).find(
        (element: string) => element === type
      )
    ) {
      return Axios.get(`/json/${type}.json?nocache=${new Date().getTime()}`, {
        validateStatus: () => true,
      });
    }
  }
}

export const Api = new ApiService();
