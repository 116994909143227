<template>
  <span :data-bsc-tooltip="content" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TooltipComponent',
  props: {
    content: { type: String, required: true },
  },
});
</script>

<style lang="scss">
@import "@/styles/misc/tooltip.scss";
</style>