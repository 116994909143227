import { vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = ["id", "name", "data-testid"]
const _hoisted_3 = {
  key: 1,
  class: "fs-5 text-dark"
}
const _hoisted_4 = {
  key: 2,
  class: "fs-5 text-dark"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tooltip = _resolveComponent("Tooltip")!
  const _component_LabelComponent = _resolveComponent("LabelComponent")!

  return (_ctx.validateMode())
    ? (_openBlock(), _createElementBlock("fieldset", {
        key: 0,
        class: _normalizeClass('form-check form-switch mt-3 field-is-' + _ctx.type),
        disabled: _ctx.isDisabled(_ctx.fieldId)
      }, [
        _withDirectives(_createElementVNode("input", {
          id: _ctx.fieldId,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
          type: "checkbox",
          class: "form-check-input",
          name: _ctx.fieldId,
          "data-testid": _ctx.fieldId + '-toggle'
        }, null, 8 /* PROPS */, _hoisted_2), [
          [_vModelCheckbox, _ctx.value]
        ]),
        (_ctx.label)
          ? (_openBlock(), _createBlock(_component_LabelComponent, {
              key: 0,
              class: "form-check-label",
              label: _ctx.label,
              "label-for": _ctx.fieldId
            }, {
              after: _withCtx(() => [
                (_ctx.tooltip)
                  ? (_openBlock(), _createBlock(_component_Tooltip, {
                      key: 0,
                      content: _ctx.tooltip[_ctx.$i18n.locale]
                    }, null, 8 /* PROPS */, ["content"]))
                  : _createCommentVNode("v-if", true),
                (_ctx.value)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.statusOn[_ctx.$i18n.locale]), 1 /* TEXT */))
                  : (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.statusOff[_ctx.$i18n.locale]), 1 /* TEXT */))
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["label", "label-for"]))
          : _createCommentVNode("v-if", true)
      ], 10 /* CLASS, PROPS */, _hoisted_1))
    : _createCommentVNode("v-if", true)
}