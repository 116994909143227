<template>

  <CompanyCode
    v-if="showModal"
    @hideModal="toggleModal"
  />

  <p v-if="formNotFound" class="text-danger text-center py-4">
    {{ t("message.formNotFound") }}
  </p>

  <header id="calculator-header">
    <nav class="meta bg-primary text-white">
      <div class="container-fluid">
        <div class="row" style="align-items: center;">
          <div class="col-md col-5 text-truncate" :title="employer?.company">
            <span class="icon-office" />
            {{ t("message.company") }}:
            <strong data-testid="header-company-name-text">{{ employer?.company ?? "---" }}</strong>
            <ChangeCompanyButton :modal="modal" :toggleModal="toggleModal" :display-edit-button="true" />
            <br>
          </div>
          <div class="col-md-auto col-5 text-truncate" v-if="hasExpandedMode">
            <SimpleToggleComponent fieldId="formMode" :statusOff="t('message.normal')"
                                   :statusOn="t('message.expanded')" data-testid="header-mode-toggle"/>
          </div>
          <div class="col-md-auto col-xs-6 text-end" style="margin-left: auto">
            <LanguageSwitch v-show="!getLanguageParams"/>
          </div>
        </div>
      </div>
    </nav>
  </header>

  <div
    :class="formClass"
  >
    <NavTabs
      :steps="steps"
      :active-step="activeStep"
      @clicked="changeStepTo"
    />

    <div v-if="!submit">
      <template
        v-for="(step,index) in steps"
        :key="step.id"
      >
        <div
          v-if="activeStep === index"
          class="row step"
        >
          <div class="col">
            <p class="h2 mt-3" data-testid="page-title-text">
              {{ step.headline[$i18n.locale] }}
            </p>

            <template
              v-for="field in step['fields']"
              :key="field.id"
            >
              <FieldsComponent :field-data="field" />
            </template>
          </div>
          <div class="col-12 my-3">
            <ButtonNavigation :index="index" :totalSteps="steps.length - 2" :submitForm="submitForm"
                              :changeStepTo="changeStepTo" />
            <div v-if="errorMsg !== ''" style="padding-top: 0.5em; color: red; text-align:center;">
              {{ errorMsg }}
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>

  <div v-if="submit">
    <component
      :is="resultComponent"
      :form-data="formData"
      :result-data="resultData"
    />
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="border-top pt-3" />
          <Button
            :text="t('message.prev')"
            class="btn-light"
            @click="changeStepTo(activeStep, true)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
// Child components
import FieldsComponent from "@/components/form/FieldsComponent.vue";
import Button from "@/components/ButtonComponent.vue";
import LanguageSwitch from "@/components/LanguageSwitch.vue";
import SimpleToggleComponent from "@/components/form/SimpleToggleComponent.vue";
import ButtonNavigation from "@/components/ButtonNavigation.vue";
import NavTabs from "@/components/form/NavTabsComponent.vue";
import CompanyCode from "@/components/form/CompanyCodeComponent.vue";
import ResultView from "@/views/ResultView.vue";
import ResultViewSelfEmployed from "@/views/ResultViewSelfEmployed.vue";
import ResultViewAt from "@/views/ResultViewAt.vue";
import ResultViewFesto from "@/views/ResultViewFesto.vue";
import ChangeCompanyButton from "@/components/ChangeCompanyButton.vue";

// Types and Interfaces
import { getColoringFromUrl } from "@/helper/coloring-helper";
import {FormMode, VALID_COUNTRIES} from "@/enums/Application";
import { AxiosError } from "axios";
import { ApiFormJsonPayload } from "@/interfaces/api-payloads";
import { IFormValues } from "@/store/interfaces/IFormValues";

// Vue tooling
import { ComponentPublicInstance, computed, onBeforeMount, ref, watch } from "vue";
import { store } from "@/store/store";
import { useTranslator } from "@/composables/useTranslator";
import { useGtm } from "@gtm-support/vue-gtm";
import { Api } from "@/services/api-service";
import { defaultEmployer } from "@/store/defaults";
import { mapFormDefaultValues } from "@/mapper/form-defaults-mapper";
import { validateform } from "@/validation/validateform";
import { useUrlParams } from "@/composables/useRoutingParameters";
import { IApiResult } from "@/interfaces/api-result.employee";

// Store and global i18n translator
const { t } = useTranslator();
const $gtm = useGtm();

// Employer data
const employer = computed(() => store.state.employer);
const code = ref<string>("");


/**********  COLOR HANDLING *********+*/
const colors = ref({
  primary: store.getters.getPrimaryColor,
  secondary: store.getters.getSecondaryColor
});
watch(() => store.state.employer.primaryColor, () => {
  colors.value.primary = store.getters.getPrimaryColor;
});

watch(() => store.state.employer.secondaryColor, () => {
  colors.value.secondary = store.getters.getSecondaryColor;
});


/**********  MODAL COMPONENT *********+*/
const modal = ref<number>(1);
const showModal = ref<boolean>(true);
const toggleModal = () => {
  showModal.value = !showModal.value;
};


/*************** Form settings AND URL Parameters ***************/
const formType = ref<FormMode>(store.state.appData.formType);
const formNotFound = ref<boolean>(false);

// If the formtype is not found, hide the modal (because we will show the errorMsg)
watch(formNotFound, () => {

  if (formNotFound.value) {
    showModal.value = false;
  }
});

const { getUrlParam, isUrlParam } = useUrlParams();

const getCodeByURL = async () => {
  const token = getUrlParam("token");
  let urlCode = getUrlParam("code");

  // TODO: What for we need this?
  if (token) {
    // const response = await fetch("https://calculator-backend-dev.bikeleasing.de/api/v1/employer?token="+token),
    // data = await response.json();
    // companyData.value = data;
    // code.value = data.toString();
  } else {
    if (urlCode) {
      urlCode = urlCode.toUpperCase();
      store.commit("setCode", urlCode);
      const attemptToSetEmployerByCode = store.dispatch("setEmployerByCompanyCode", urlCode);

      if (attemptToSetEmployerByCode) {
        showModal.value = false;
      } else {
        store.dispatch("resetEmployer");
        showModal.value = true;
      }
    }
  }
};

const getFormByURL = () => {

  let formMode: FormMode = FormMode.EMPLOYEE;
  const formTypeParam = getUrlParam("form");

  if(formTypeParam && !isUrlParam('form', '')) {
    formMode = formTypeParam as FormMode;
  }  

  if(isUrlParam('country', VALID_COUNTRIES.AUSTRIA) && formMode === FormMode.EMPLOYEE || formMode === FormMode.EMPLOYEE_AT) {
    formMode = FormMode.EMPLOYEE_AT;
  }

  if (formMode) {
    formType.value = formMode;
    store.commit("setFormType", formType.value);
  }
};

/**
 * Check if we need the modal. This is per default the case
 * exception is, if the URL explicitly states that the modal should not be shown ?modal=0
 */
const getModalByURL = () => {
  const modalUrl = getUrlParam("modal");

  if (modalUrl) {
    modal.value = Number(modalUrl);
  }

  if (modal.value === 0) {
    showModal.value = false;
  }

};

/**
 * At moment we don't support Collective Agreement and Beamte for Austria
 *
 */
const checkIfFormShouldExist = () => {

  const countryNotGermany = store.state.appData.country !== VALID_COUNTRIES.GERMANY;
  const formIsOfficial = store.state.appData.formType === FormMode.OFFICIAL;
  const formIsCollectiveAgreement = store.state.appData.formType === FormMode.COLLECTIVE_AGREEMENT;

  formNotFound.value = countryNotGermany && (formIsOfficial || formIsCollectiveAgreement);
};


// Computed properties
const hasExpandedMode = computed<boolean>(() => {
  return store.state.appData.formType != FormMode.SELF_EMPLOYED
    && store.state.appData.formType != FormMode.OFFICIAL
    && store.state.appData.formType != FormMode.SELF_EMPLOYED_AT;
});
const formClass = computed(() => {
  return !showModal.value ? "container-fluid calculator" : "container-fluid calculator blurred";
});

const getLanguageParams = computed(()=> {
  return getUrlParam('hideFlag') && getUrlParam('hideFlag').toLocaleLowerCase() === 'true';
});


const steps = ref<ApiFormJsonPayload>({} as ApiFormJsonPayload);
const activeStep = ref<number>(0);

const changeStepTo = (step: number, submitted = false) => {

  if (submitted) {
    // From submit to last input screen
    activeStep.value = steps.value.length - 2;
    submit.value = false;
  } else if (step === steps.value.length - 1) {
    // Is clicking on the result button, calculate the result
    submitForm();
    submit.value = true;
  } else {
    if (validateform(store.state, t) && typeof step !== "undefined") {
      activeStep.value = step;
      submit.value = false;
    }
  }
};


const errorMsg = ref<string>("");

const initData = async () => {
  await Api.getForm(formType.value).then((response) => {
    const form = response?.data ?? null;

    if (form) {
      store.commit("setForm", formType.value);
      // TODO: This will be possibly collide with setEmployer, since both modifiy the same fields
      mapFormDefaultValues(store.state);
      steps.value = form;
    } else {
      formNotFound.value = true;
      showModal.value = false;
    }
  }).catch(error => console.log(error));
};


/***************** RESULT HANDLING *****************/
const resultData = ref<IApiResult>({} as IApiResult);
const formData = ref<IFormValues>(store.state.formValues);
const submit = ref<boolean>(false);

const resultComponent = computed<ComponentPublicInstance>((): ComponentPublicInstance => {

  // TODO: Make this form generally available and not dependent of single codes
  // TODO: Think about a better typing
  if (code.value === "5G2X" || formType.value === FormMode.COLLECTIVE_AGREEMENT) {
    return ResultViewFesto as unknown as ComponentPublicInstance;
  }

  if ([FormMode.SELF_EMPLOYED, FormMode.SELF_EMPLOYED_AT].includes(formType.value)) {
    return ResultViewSelfEmployed as unknown as ComponentPublicInstance;
  }

  if (formType.value === FormMode.EMPLOYEE_AT) {
    return ResultViewAt as unknown as ComponentPublicInstance;
  }

  return ResultView as unknown as ComponentPublicInstance;

});

const submitForm = async () => {
  let error: unknown | Error | AxiosError;
  try {
    if (employer.value !== defaultEmployer) {
      Api.setEmployer(employer.value);
    }
    Api.setState(store.state);
    resultData.value = await Api.getCalculationResult(formType.value, formData.value);
    submit.value = true;
    activeStep.value = steps.value.length -1 ;
  } catch (e: unknown | Error | AxiosError) {
    if (e instanceof AxiosError) {
      errorMsg.value = e.response.data.message;
    }
    submit.value = false;
    error = e;
  } finally {
    if (!error) {
      if (isUrlParam('track','true') && $gtm.enabled()) {
        const country: string = store.state.appData.country.toLowerCase() ?? "de";
        window.dataLayer?.push({
          "event": "form-sent",
          "form-id": `bikeleasing_${country.toLowerCase()}_leasing_calculator`
        });
      }
    }
  }
};


// Vue Lifecycle Hooks
onBeforeMount(async () => {

  // Try to get the code
  getCodeByURL();

  // Try to get / ovveride the colors
  getColoringFromUrl();

  // Check if we need the modal
  getModalByURL();

  // Try to get the form from the URL
  getFormByURL();

  // Check if the form should exist
  checkIfFormShouldExist();

  // Only run in normal mode, JEST will cause an Network Error 
  // due to the fact that /json/calculator.json is not found
  if(process.env.JEST_WORKER_ID === undefined) {
    await initData();
  }

});

</script>


<style lang="scss">
@import "@/styles/elements/calculator.scss";

.form-range::-webkit-slider-thumb,
[data-bsc-tooltip]:before,
[data-bsc-tooltip]:after,
.form-check-input:checked,
.bg-primary,
.btn-check:checked + .btn-primary,
.btn-check:active + .btn-primary,
.btn-primary:active,
.btn-primary.active,
.show > .btn-primary.dropdown-toggle,
.btn-check:focus + .btn-primary,
.btn-primary:focus {
  background-color: v-bind('colors.primary.hex') !important
}

.nav-link:hover, .nav-link:focus {
  color: v-bind('colors.primary.hex') !important;
  opacity: 0.8;

}

.form-check-label,
.form-label {
  color: v-bind('colors.primary.hex') !important
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link,
.btn-primary,
.text-primary,
.accordion-head,
a {
  color: v-bind('colors.primary.hex') !important
}

.btn-primary:hover {
  background-color: v-bind('colors.primary.hex') !important;
  color: #fff !important;
}

.border-primary,
.accordion-head,
.form-select:focus,
.form-check-input:focus,
.form-check-input:checked,
.form-control:focus,
.btn-primary,
.btn-check:focus + .btn-primary,
.btn-primary:focus {
  border-color: v-bind('colors.primary.hex') !important;
}

// .form-check-input:checked,
// .form-switch .form-check-input:focus {
// 	filter: grayscale(1);
// }
.form-range::-webkit-slider-thumb,
.form-check-input:checked {
  background-color: v-bind('colors.primary.hex') !important;
}

.form-control:focus,
.form-check-input:focus,
.form-check-input:checked,
.form-select:focus {
  border-color: v-bind('colors.secondary.hex') !important;;
}

.btn-check:focus + .btn-primary, .btn-primary:focus {
  color: #fff !important;
}

.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill%3D%27%23c7c7c7%27/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill%3D%27%23ffffff%27/%3e%3c/svg%3e");
}


.btn-swal-error {
  background-color: #fff;
  color: #545454;
  font-weight: bold;
  border: 2px solid #545454;
  padding: 10px 10px;
  text-transform: uppercase;
  border-radius: 4px;

  &:hover {
    background-color: #f8f7f7;
  }
}
</style>

<style>
.btn-check:focus + .btn, .btn:focus,
.form-range:focus::-webkit-slider-thumb,
.btn-check:checked + .btn-primary:focus,
.btn-check:active + .btn-primary:focus,
.btn-primary:active:focus,
.btn-primary.active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgb(v-bind('colors.primary.r'), v-bind('colors.primary.g'), v-bind('colors.primary.b') / 25%)
}

.form-select:focus,
.form-control:focus,
.form-check-input:focus {
  box-shadow: 0 0 0 0.25rem rgb(v-bind('colors.secondary.r'), v-bind('colors.secondary.g'), v-bind('colors.secondary.b') / 25%) !important
}
</style>
