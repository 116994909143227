<template>
  <fieldset :ref="fieldId" :class="'form-floating mt-3 field-is-'+type" v-if="validateMode()" :disabled="isDisabled(fieldId)">
    <select :id="fieldId" ref="select" v-model="selected" :class="'form-select '+requiredClass" :name="fieldId" :required="required">
      <option v-for="item in options" :key="item.value" :data-label="item.label" :value="item.value">
        {{ item[$i18n.locale] }}
      </option>
    </select>
    <LabelComponent :label="label" :label-for="fieldId" />
  </fieldset>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import LabelComponent from '@/components/form/LabelComponent.vue';
import { FormPayload, store } from '@/store/store';
import { ComponentMixins } from './component-mixins';
import emitter from "@/services/emitter";

export default defineComponent({
  name: 'SelectComponent',
  components: {
    LabelComponent,
  },
  mixins: [ComponentMixins],
  props: {
    label: { type: String, required: true },
    defaultVal: { type: String, default: '---' },
    options: { type: Object, required: true },
    fieldId: { type: String, required: true },
    type: { type: String, required: true },
    required: { type: Boolean },
    isExpanded: { type: Boolean, default: false }
  },
  data() {
    return {
      id: Math.ceil(Math.random()*1000000),
      requiredClass: ""
    }
  },
  watch: {
    selected() {
      store.commit('setFormValue', {key: this.fieldId, value: this.selected} as FormPayload);
    }
  },
	computed: {
		selected: {
			get(): boolean {
				return this.$store.state.formValues[this.fieldId];
			},
			set(value: boolean) {
				this.$store.state.formValues[this.fieldId] = value;
        emitter.emit("refreshStore");
			}
		},
    expandedMode() {
      return this.$store.state.formValues.formMode
    }
	},
  mounted() {
    this.selected = this.$store.state.formValues[this.fieldId];
    this.requiredClass = this.getRequiredClass()
  },
  methods: {
    getRequiredClass() {
      return (this.required ? "needs-validation" : "");
    },
    validateMode() {
      return (this.isExpanded && !this.expandedMode ? false : true);
    }
  }
})
</script>

<style lang="scss">
@import "@/styles/elements/form/_all.scss";
</style>