import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "form-floating" }
const _hoisted_2 = ["id", "placeholder", "name"]
const _hoisted_3 = {
  key: 0,
  class: "text-warning mt-2"
}
const _hoisted_4 = { class: "hint-company-code" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["Modal"], {
    ref: "modal",
    "modal-title": $setup.t('message.company-code-enter')
  }, {
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _withDirectives(_createElementVNode("input", {
          id: 'input-' + $setup.id,
          type: "text",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.companyCode) = $event)),
          class: "form-control",
          placeholder: $setup.t('message.company-code'),
          name: 'input-' + $setup.id,
          onInput: $setup.handleCompanyCodeInput,
          "data-testid": "company-code-input"
        }, null, 40 /* PROPS, HYDRATE_EVENTS */, _hoisted_2), [
          [_vModelText, $setup.companyCode]
        ]),
        _createVNode($setup["Label"], {
          label: $setup.t('message.company-code'),
          "label-for": 'input-' + $setup.id
        }, null, 8 /* PROPS */, ["label", "label-for"])
      ]),
      (!$setup.companyFound && $setup.companyCode)
        ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString($setup.t('message.invalidCode')), 1 /* TEXT */))
        : _createCommentVNode("v-if", true)
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("p", null, _toDisplayString($setup.t('message.company-code-enter-hint')), 1 /* TEXT */)
      ]),
      _createElementVNode("div", null, [
        (!$setup.companyFound)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              id: "btn-no-code",
              ref: "companywo",
              class: "btn btn-light",
              type: "button",
              onClick: $setup.handleContinueWithoutCode,
              "data-testid": "without-company-code-button"
            }, _toDisplayString($setup.t('message.continue-wo-code')), 513 /* TEXT, NEED_PATCH */))
          : _createCommentVNode("v-if", true)
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modal-title"]))
}