<template>
  <button type="button" class="btn">
    <slot name="before" />
    {{ text }}
    <slot name="after" />
  </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ButtonComponent',
  props: {
    text: { type: String, required: true },
  },
});
</script>

<style lang="scss">
@import "@/styles/elements/buttons.scss";
</style>