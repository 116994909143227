export enum STANDARD_BIKE_PRICES {
    MIN = 649,
    MAX = 15000,
}

export enum LEASING_TIME {
    MONTHS_36 = 36,
    MONTHS_48 = 48,
}

/**
 * Bike Types supported by Bikeleasing
 *
 * @enum {number}
 * @readonly
 *
 * @property {number} BIKE - 1 - Bike (not e-bike)
 * @property {number} PEDELEC - 2 - Pedelec
 * @property {number} S_PEDELEC - 3 - S-Pedelec
 * @property {number} E_BIKE - 4 - E-Bike
 *
 * Bike and E_BIKE are quite straightfoward to understand.
 * The Pedelecs types, short for Pedal Electric Cycles, are bikes with an electric motor that
 * is only activated when the rider pedals, while E-bikes are bikes that don't need pedaling to activate the motor (there
 * is some computer or handlbar control to activate the motor).
 *
 * The S-Pedelec is a special type of Pedelec. It is a Pedelec that has a maximum speed of 45 km/h.
 * Since they're so fast and very close to Motorbikes, they are taxed differently. They're classified as Motorbikes in
 * many EU-countries and are taxed accordingly.
 */
export enum BIKE_TYPES {
    BIKE = 'BIKE',
    PEDELEC = 'PEDELEC',
    S_PEDELEC = 'S-PEDELEC',
    E_BIKE = 'E-BIKE',
}

export enum GERMAN_STATES {
    BUND = 'DE',
    BADEN_WUERTTEMBERG = 'BW',
    BAYERN = 'BY',
    BERLIN = 'BE',
    BRANDENBURG = 'BB',
    BREMEN = 'HB',
    HAMBURG = 'HH',
    HESSEN = 'HE',
    MECKLENBURG_VORPOMMERN = 'MV',
    NIEDERSACHSEN = 'NI',
    NORDRHEIN_WESTFALEN = 'NW',
    RHEINLAND_PFALZ = 'RP',
    SAARLAND = 'SL',
    SACHSEN = 'SN',
    SACHSEN_ANHALT = 'ST',
    SCHLESWIG_HOLSTEIN = 'SH',
    THUERINGEN = 'TH',
}
