import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form-floating" }
const _hoisted_2 = ["id", "name"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LabelComponent = _resolveComponent("LabelComponent")!

  return (_ctx.validateMode())
    ? (_openBlock(), _createElementBlock("fieldset", {
        key: 0,
        ref: _ctx.fieldId,
        class: _normalizeClass('form-floating mt-3 field-is-' + _ctx.type)
      }, [
        _createElementVNode("div", _hoisted_1, [
          _withDirectives(_createElementVNode("input", {
            id: 'input-' + _ctx.id,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
            class: "form-control number-input",
            type: "number",
            min: "0",
            name: 'input-' + _ctx.id,
            onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.change()))
          }, null, 40 /* PROPS, HYDRATE_EVENTS */, _hoisted_2), [
            [_vModelText, _ctx.value]
          ]),
          (_ctx.label)
            ? (_openBlock(), _createBlock(_component_LabelComponent, {
                key: 0,
                label: _ctx.label.toString(),
                "label-for": 'input-' + _ctx.id
              }, null, 8 /* PROPS */, ["label", "label-for"]))
            : _createCommentVNode("v-if", true)
        ])
      ], 2 /* CLASS */))
    : _createCommentVNode("v-if", true)
}