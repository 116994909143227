<template>
    <div
        class="btn-toolbar border-top pt-3 justify-content-between"
        role="group"
        aria-label="Formular Navigation"
    >
        <ButtonComponent
            v-if="index > 0"
            :text="$t('message.prev')"
            class="btn-light"
            @click="changeStepTo(index - 1)"
            data-testid="back-button"
        ></ButtonComponent>
        <ButtonComponent
            :text="$t('message.reset')"
            class="text-danger fs-6"
            @click="resetStorage()"
            data-testid="reset-button"
        >
            <template #before>
                <IconComponent icon="bin" size="6 mb-2" />
            </template>
        </ButtonComponent>
        <!-- <span v-if="index === 0" /> -->
        <ButtonComponent
            v-if="index<totalSteps"
            :text="$t('message.next')"
            class="btn-primary"
            @click="changeStepTo(index + 1)"
            data-testid="continue-button"
        />						
        <Submit
            v-if="index === totalSteps"
            :text="$t('message.submit')"
            @click="submitForm()"
            data-testid="submit-button"
        />
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import Submit from '@/components/form/SubmitComponent.vue';
import IconComponent from '@/components/IconComponent.vue';

export default defineComponent({
	name: 'ButtonNavigation',
    props: {
        index: Number,
        totalSteps: Number,
        changeStepTo: Function,
        submitForm: Function,
    },
	components: {
		ButtonComponent,
		Submit,
    IconComponent
	},
    methods: {
        resetStorage() {
            localStorage.removeItem('store');
            location.reload();
        }
    }
});

</script>
