<template>
  <section class="section is-large my-3">
    <div class="container-fluid">
      <h1 data-testid="result-title-text">{{ $t("message.resultTableHeadline") }}</h1>
      <div class="row mt-3 quick-overview">
        <div class="col-xs-12 col-md-3 text-right">
              <div class="product-badge hidden-md mb-2">
                <div class="inner-badge">
                  <span class="percent" id="saving-percent-badge"><span>{{formatToPercent(resultData.savings.percentageSavings)}}</span></span>
                  <span class="text">
                    <span class="bigtext">{{ $t("message.productBadgeBigText") }}</span>
                    <span class="smalltext">{{ $t("message.productBadgeSmallText") }}</span>
                  </span>
                </div>
              </div>
        </div>

        <div class="col-xs-12 col-md-9">
          <table class="table">
            <tr v-if="formData.maximumNetCharge > 0">
              <td class="bold">{{ $t("message.maxBikePrice") }}</td>
              <td />
              <td
                class="text-end bold"
              >
              {{formatToEuro(resultData.bikeCosts.bikePrice)}}
              </td>
            </tr>
            <tr>
              <td>{{ $t("message.quickOverviewTd1") }}</td>
              <td />
              <td
                class="text-end"
              >
              {{formatToEuro(resultData.monthlyCosts.employeeCosts.monthlyLeasingRate)}}
              </td>
            </tr>
            <tr>
              <td>{{ $t("message.quickOverviewTd2") }}</td>
              <td />
              <td
                class="text-end"
              >
              {{calculateLeasingTotalValue(resultData)}}
              </td>
            </tr>
            <tr>
              <td><strong class="text-primary">{{ $t("message.quickOverviewTd3") }}</strong></td>
              <td />
              <td class="text-end bg-primary text-white">
                <strong>{{formatToEuro(resultData.monthlyCosts.employeeCosts.salaryConversion)}}</strong>
              </td>
            </tr>
            <tr>
              <td><strong class="text-primary">{{ $t("message.quickOverviewTd4") }}</strong></td>
              <td />
              <td class="text-end bg-primary text-white">
                <strong>{{formatToEuro(resultData.monthlyCosts.employeeCosts.netCharge)}}</strong>
              </td>
            </tr>
            <tr>
              <td><strong>{{ $t("message.quickOverviewTd5") }}</strong></td>
              <td class="text-end">
                <strong><span>{{formatToPercent(resultData.savings.percentageSavings)}}</span></strong>
              </td>
              <td class="text-end savings-padding">
                <strong>{{formatToEuro(resultData.savings.totalSavings)}}</strong>
              </td>
            </tr>
          </table>
        </div>

        <div class="col-xs-12">
          <template v-if="formJson !== 'selfemployed'">
            <strong
              class="accordion-head collapsed d-flex"
              role="button"
              :class="{ active: leasingTableActive }"
              @click="leasingTableActive = !leasingTableActive"
            >{{ $t("message.table1AccordionHeader") }}<span class="pull-right" /></strong>

            <div
              class="accordion-body"
              :class="{ active: leasingTableActive }"
            >
              <div class="responsive-table">
                <table class="table">
                  <tr>
                    <th>{{ $t("message.tableHead1") }}</th>
                    <th class="text-end">
                      {{ $t("message.tableHead2") }}
                    </th>
                    <th class="text-end">
                      {{ $t("message.tableHead3") }}
                    </th>
                  </tr>
                  <tr>
                    <td>{{$t('message.monthlyIncome')}}</td>
                    <td
                      class="text-end"
                    >
                    {{formatToEuro(formData.monthlyIncome)}}
                    </td>
                    <td
                      class="field text-end"
                    >
                    {{formatToEuro(formData.monthlyIncome)}}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {{$t('message.calculatedCostEmployeeLeasing')}}
                    </td>
                    <td class="text-end" />
                    <td
                      class="field text-end negative-number"
                    >
                    {{formatToEuro(resultData.monthlyCosts.employeeCosts.monthlyLeasingRate - resultData.monthlyCosts.employerCosts.agavCosts)}}
                    </td>
                  </tr>
                  <tr v-if="resultData.monthlyCosts.employerCosts.agavCosts">
                    <td>
                      {{ $t('message.agavBonusIncludedInLeasingRate') }}
                    </td>
                    <td class="text-end" />
                    <td
                      class="field text-end"
                    >
                    {{formatToEuro(resultData.monthlyCosts.employerCosts.agavCosts,true)}}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {{$t('message.calculatedCostEmployeeInsurancePremium')}}
                    </td>
                    <td class="text-end" />
                    <td
                      class="field text-end negative-number"
                    >
                    {{formatToEuro(resultData.monthlyCosts.employeeCosts.insuranceComfort)}}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {{$t('message.calculatedCostEmployeeInsurancePremiumPlus')}}
                    </td>
                    <td class="text-end" />
                    <td
                      class="field text-end negative-number"
                    >
                    {{formatToEuro(resultData.monthlyCosts.employeeCosts.insuranceWearAndTear)}}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {{$t('message.calculatedCostEmployeeInsuranceInspection')}}
                    </td>
                    <td class="text-end" />
                    <td
                      class="field text-end negative-number"
                    >
                    {{formatToEuro(resultData.monthlyCosts.employeeCosts.inspection)}}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {{$t('message.calculatedCostEmployerLeasing')}}
                    </td>
                    <td class="text-end" />
                    <td
                      class="field text-end"
                    >
                    {{formatToEuro(resultData.monthlyCosts.employerCosts.leasingRate)}}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {{$t('message.calculatedCostEmployerInsurancePremium')}}
                    </td>
                    <td class="text-end" />
                    <td
                      class="field text-end"
                    >
                    {{formatToEuro(resultData.monthlyCosts.employerCosts.insuranceComfort)}}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {{$t('message.calculatedCostEmployerInsurancePremiumPlus')}}
                      </td>
                    <td class="text-end" />
                    <td
                      class="field text-end"
                    >
                    {{formatToEuro(resultData.monthlyCosts.employerCosts.insuranceWearAndTear)}}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {{$t('message.calculatedCostEmployerInsuranceInspection')}}
                    </td>
                    <td class="text-end" />
                    <td
                      class="field text-end"
                    >
                    {{formatToEuro(resultData.monthlyCosts.employerCosts.inspection)}}
                    </td>
                  </tr>
                  <tr class="break">
                    <td>
                      {{$t('message.calculatedGrossIncomeAfterCalculation')}}
                    </td>
                    <td
                      class="text-end"
                    >
                    {{formatToEuro(resultData.taxCalculationWithoutLeasing.taxableIncome)}}
                    </td>
                    <td
                      class="field text-end"
                    >{{formatToEuro(resultData.taxCalculationWithLeasing.grossIncomeAfterCalculation)}}</td>
                  </tr>
                  <tr>
                    <td>
                      {{$t('message.calculatedGeldwerterVorteil')}}
                    </td>
                    <td class="text-end" />
                    <td
                      class="field text-end"
                    >
                    {{formatToEuro(resultData.taxCalculationWithLeasing.geldwerterVorteil)}}
                    </td>
                  </tr>

                  <tr class="bold">
                    <td>
                      {{$t('message.calculatedVersteuerbaresEinkommen')}}
                      </td>
                    <td
                      class="text-end"
                    >{{formatToEuro(resultData.taxCalculationWithoutLeasing.taxableIncome)}}</td>
                    <td
                      class="field text-end"
                    >
                    {{formatToEuro(resultData.taxCalculationWithLeasing.taxableIncome)}}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {{$t('message.calculatedLohnsteuer')}}
                      </td>
                    <td
                      class="text-end"
                    >
                    {{formatToEuro(resultData.taxCalculationWithoutLeasing.incomeTaxAmount)}}
                    </td>
                    <td
                      class="field text-end">{{formatToEuro(resultData.taxCalculationWithLeasing.incomeTaxAmount)}}</td>
                  </tr>
                  <tr>
                    <td>{{$t('message.calculatedSolidaritaetszuschlag')}}</td>
                    <td
                      class="text-end">{{formatToEuro(resultData.taxCalculationWithoutLeasing.solidarityContribution)}}</td>
                    <td
                      class="field text-end">{{formatToEuro(resultData.taxCalculationWithLeasing.solidarityContribution)}}</td>
                  </tr>
                  <tr>
                    <td>{{$t('message.calculatedKirchensteuer')}}</td>
                    <td
                      class="text-end">{{formatToEuro(resultData.taxCalculationWithoutLeasing.churchTaxAmount)}}</td>
                    <td
                      class="field text-end">{{formatToEuro(resultData.taxCalculationWithLeasing.churchTaxAmount)}}</td>
                  </tr>
                  <tr>
                    <td>{{$t('message.calculatedKrankenversicherung')}}</td>
                    <td
                      class="text-end">{{formatToEuro(resultData.taxCalculationWithoutLeasing.healthInsuranceAmount)}}</td>
                    <td
                      class="field text-end">{{formatToEuro(resultData.taxCalculationWithLeasing.healthInsuranceAmount)}}</td>
                  </tr>
                  <tr v-if="formData.healthcareType === HEALTHCARE_TYPE.PUBLIC">
                    <td>{{$t('message.calculatedPflegeversicherung')}}</td>
                    <td
                      class="text-end">{{formatToEuro(resultData.taxCalculationWithoutLeasing.healthCareInsuranceAmount)}}</td>
                    <td
                      class="field text-end">{{formatToEuro(resultData.taxCalculationWithLeasing.healthCareInsuranceAmount)}}</td>
                  </tr>
                  <tr v-if="formData.healthcareType === HEALTHCARE_TYPE.PUBLIC">
                    <td>{{$t('message.calculatedRentenversicherung')}}</td>
                    <td
                      class="text-end">{{formatToEuro(resultData.taxCalculationWithoutLeasing.pensionInsuranceAmount)}}</td>
                    <td
                      class="field text-end">{{formatToEuro(resultData.taxCalculationWithLeasing.pensionInsuranceAmount)}}</td>
                  </tr>
                  <tr>
                    <td>{{$t('message.calculatedAeitslosenversicherung')}}</td>
                    <td
                      class="text-end">{{formatToEuro(resultData.taxCalculationWithoutLeasing.unemploymentInsuranceAmount)}}</td>
                    <td
                      class="field text-end">{{formatToEuro(resultData.taxCalculationWithLeasing.unemploymentInsuranceAmount)}}</td>
                  </tr>

                  <tr class="break">
                    <td>{{$t('message.comparisonNetIncome')}}</td>
                    <td
                      class="text-end">{{formatToEuro(resultData.taxCalculationWithoutLeasing.netIncome)}}</td>
                    <td
                      class="field text-end">{{formatToEuro(resultData.taxCalculationWithLeasing.netIncome)}}</td>
                  </tr>
                  <tr>
                    <td>{{$t('message.calculatedGeldwerterVorteilAbzug')}}</td>
                    <td class="text-end" />
                    <td
                      class="field text-end negative-number">{{formatToEuro(resultData.taxCalculationWithLeasing.geldwerterVorteil)}}</td>
                  </tr>

                  <tr v-if="formData.healthcareType !== HEALTHCARE_TYPE.PUBLIC">
                    <td>{{$t('message.contributionToPrivateHealthCareInsurance')}}</td>
                    <td
                      class="text-end negative-number">{{formatToEuro(calculateInsuranceContribution(resultData))}}</td>
                    <td
                      class="field text-end negative-number">{{formatToEuro(calculateInsuranceContribution(resultData))}}</td>
                  </tr>


                  <tr class="bold">
                    <td>{{$t('message.calculatedAuszahlungsbetrag')}}</td>
                    <td
                      class="text-end">{{formatToEuro(resultData.taxCalculationWithoutLeasing.payoutAmount)}}</td>
                    <td
                      class="field text-end">{{formatToEuro(resultData.taxCalculationWithLeasing.payoutAmount)}}</td>
                  </tr>
                  <tr class="break-grey">
                    <td>{{$t('message.calculatedNettoBelastung')}}</td>
                    <td class="text-end" />
                    <td
                      class="field text-end">{{formatToEuro(resultData.taxCalculationWithLeasing.netCharge)}}</td>
                  </tr>
                </table>
              </div>
            </div>

            <strong
              class="accordion-head collapsed d-flex"
              role="button"
              :class="{ active: comparePurchaseTableActive }"
              @click="comparePurchaseTableActive = !comparePurchaseTableActive"
            >{{ $t("message.table2AccordionHeader") }}<span class="pull-right" /></strong>

            <div
              class="accordion-body"
              :class="{ active: comparePurchaseTableActive }"
            >
              <div class="responsive-table">
                <table class="table compare-purchase">
                  <tr>
                    <td>
                      {{ $t('message.totalsTotalLeasingRates') }}
                      <span>{{ formData.leasingTime }} {{ $t('message.month') }}</span>
                    </td>
                    <td class="text-end" />
                    <td
                      class="field text-end">{{formatToEuro(resultData.totalCosts.totalLeasingRates)}}</td>
                  </tr>
                  <tr>
                    <td>{{$t('message.calculatedRemainingBikeValue')}}</td>
                    <td
                      class="text-end">{{formatToEuro(resultData.bikeCosts.bikePrice)}}</td>
                    <td
                      class="field text-end">{{formatToEuro(resultData.bikeCosts.remainingBikeValue)}}</td>
                  </tr>
                  <tr>
                    <td>
                      {{ $t('message.totalsTotalCostOfLeasing') }}
                      <span>{{ formData.leasingTime }} {{ $t('message.month') }}</span>
                    </td>
                    <td
                      class="text-end">{{formatToEuro(resultData.totalCosts.totalCostOfPurchase)}}</td>
                    <td
                      class="field text-end">{{formatToEuro(resultData.totalCosts.totalCostOfLeasing)}}</td>
                  </tr>
                  <tr class="break">
                    <td>{{$t('message.savingsAbsolute')}}</td>
                    <td
                      class="text-end">{{formatToPercent(resultData.savings.percentageSavings)}}</td>
                    <td
                      class="field text-end">{{formatToEuro(resultData.savings.totalSavings)}}</td>
                  </tr>
                </table>
              </div>
            </div>
          </template>

          <template v-if="formJson == 'selfemployed'">
            <strong
              class="accordion-head collapsed d-flex"
              role="button"
              :class="{ active: comparePurchaseTableActive }"
              @click="comparePurchaseTableActive = !comparePurchaseTableActive"
            >{{ $t("message.table2AccordionHeader") }}<span class="pull-right" /></strong>

            <div
              class="accordion-body"
              :class="{ active: comparePurchaseTableActive }"
            >
              <div class="responsive-table">
                <table class="table compare-purchase">
                  <tr>
                    <td>
                      {{ $t('message.selfemployedTotalsTotalLeasingRates') }}
                      <span>({{ formData.leasingTime }} {{ $t('message.month') }})</span>
                    </td>
                    <td
                      class="field text-end">{{formatToEuro(resultData.monthlyCosts.employeeCosts.monthlyLeasingRate)}}</td>
                  </tr>
                  <tr>
                    <td>
                      {{ $t('message.selfemployedTotalsTotalCostOfInsurance') }}
                      <span>({{ formData.leasingTime }} {{ $t('message.month') }})</span>
                    </td>
                    <td
                      class="field text-end">{{formatToEuro(resultData.monthlyCosts.employeeCosts.insuranceComfort)}}</td>
                  </tr>
                  <tr class="fw-bold">
                    <td>
                      {{ $t('message.selfemployedTotalsTotalCostOfLeasing') }}
                      <span>({{ formData.leasingTime }} {{ $t('message.month') }})</span>
                    </td>
                    <td
                      class="field text-end">{{formatToEuro(resultData.monthlyCosts.employeeCosts.monthlyLeasingRate + resultData.monthlyCosts.employeeCosts.insuranceWearAndTear)}}</td>
                  </tr>
                </table>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent, PropType, toRaw } from "vue";
import {Api} from '@/services/api-service';
import {ValidLocales} from '@/enums/Application';
import { HEALTHCARE_TYPE, IFormValues } from "@/store/interfaces/IFormValues";
import { IApiResult } from "@/interfaces/api-result.employee";

export default defineComponent({
	name: 'ResultView',
	props: {
		formData: { type: Object as PropType<IFormValues>, default: null },
		resultData: { type: Object as () => IApiResult, default: null },
	},
	data() {
		return {
			result: toRaw(this.resultData),
			leasingTableActive: false,
			comparePurchaseTableActive: false,
		}
	},
  computed: {
      HEALTHCARE_TYPE() {
          return HEALTHCARE_TYPE
      },
    formJson() {
      return this.$store.state.appData.formType;
    },
  },
	methods: {
		formatToEuro(val = 0, negative = false): string {
      let value;
      if(val) {
        value = val.toLocaleString(ValidLocales.GERMANY, {
          style: 'currency',
          currency: 'EUR',
          minimumFractionDigits: 2
        });

        if(negative) {
          return "- " + value;
        } else {
          return value;
        }

      } else {
        return '0,00 €';
      }
		},
		formatToPercent(val: number): string {
			if(val)
				return val.toFixed(0) + "%";
			else
				return '0 %';
		},
    calculateInsuranceContribution(resultData: IApiResult) {
      const calculationResult = resultData.taxCalculationWithLeasing.sumPkvPv;
      switch(this.formData.healthcareType) {
        case HEALTHCARE_TYPE.PRIVATE_WITH_CONTRIBUTION:
          return calculationResult / 2;
        case HEALTHCARE_TYPE.PRIVATE_WITHOUT_CONTRIBUTION:
          return calculationResult;
        case HEALTHCARE_TYPE.PUBLIC:
          return 0;
      }
    },
		calculateLeasingTotalValue(data: IApiResult): string {
			const employeeResult = data.monthlyCosts.employeeCosts;
			return this.formatToEuro(employeeResult.monthlyLeasingRate +
					employeeResult.insuranceComfort +
					employeeResult.insuranceWearAndTear +
					employeeResult.inspection
         );
		},
		myFilter(): void {
			this.leasingTableActive = !this.leasingTableActive;
			this.comparePurchaseTableActive = !this.comparePurchaseTableActive;
		},
	},
})

</script>

<style lang="scss">
@import "@/styles/elements/results.scss";
</style>