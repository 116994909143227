<template>

  <section class="section is-large my-3">
    <div class="container-fluid">
      <h1 data-testid="result-title-text">{{ $t("message.resultTableHeadline") }}</h1>
      <div class="row mt-3 quick-overview">
        <div class="col-xs-12 col-md-6">
          <div class="row">
            <div class="col-lg-6 col-md-12 mb-2">
              &nbsp;
            </div>
          </div>
        </div>

        <div class="col-xs-12">
            <strong
              class="d-flex "
            ><span class="pull-right" /></strong>

            <div class="responsive-table">
              <table class="table compare-purchase">
                <tr>
                  <th class="border-top-0" colspan="2">
                    {{ $t("message.costOverview") }}
                  </th>
                </tr>
                <tr>
                  <td>
                    {{ $t('message.selfemployedTotalsTotalLeasingRates') }}
                    <span>({{$store.state.formValues.leasingTime || 36}} {{ $t('message.month') }})</span>
                  </td>
                  <td
                    class="field text-end"
                    >{{formatToEuro(resultData.monthlyCosts.monthlyLeasingRate)}}</td>
                </tr>

                <tr>
                  <td>
                    {{ $t('message.selfemployedTotalsTotalCostOfInsurance') }}
                    <span>({{$store.state.formValues.leasingTime || 36}} {{ $t('message.month') }})</span>
                  </td>
                  <td
                    class="field text-end"
                    >{{formatToEuro(resultData.monthlyCosts.insuranceComfort)}}</td>
                </tr>

                <tr v-if="resultData.monthlyCosts.insuranceWearAndTear > 0">
                  <td>
                    {{ $t('message.selfemployedCostOfInsurancePremiumPlus') }}
                    <span>({{$store.state.formValues.leasingTime || 36}} {{ $t('message.month') }})</span>
                  </td>
                  <td
                    class="field text-end"
                    >{{formatToEuro(resultData.monthlyCosts.insuranceWearAndTear)}}</td>
                </tr>
                <tr v-if="resultData.monthlyCosts.inspection > 0">
                  <td>
                    {{ $t('message.selfemployedCostOfInsuranceInspection') }}
                    <span>({{$store.state.formValues.leasingTime || 36}} {{ $t('message.month') }})</span>
                  </td>
                  <td
                    class="field text-end"
                    >{{formatToEuro(resultData.monthlyCosts.inspection)}}</td>
                </tr>

                <tr class="fw-bold">
                  <td>
                    {{ $t('message.selfemployedTotalsTotalCostOfLeasing') }}
                    <span>({{$store.state.formValues.leasingTime || 36}} {{ $t('message.month') }})</span>
                  </td>
                  <td
                    class="field text-end"
                    >{{formatToEuro(resultData.monthlyCosts.leasingTotalMonthly)}}</td>
                </tr>
              </table>
            </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent, toRaw } from "vue";
import { Api } from "@/services/api-service";
import { ValidLocales } from "@/enums/Application";
import { IApiResultSelfEmployed } from "@/interfaces/api-result.self-employed";

export default defineComponent({
	name: 'ResultViewSelfEmployed',
	props: {
		formData: { type: Object, default: null },
		resultData: { type: Object as () => IApiResultSelfEmployed, default: null },
	},
	data() {
		return {
			result: toRaw(this.resultData),
			leasingTableActive: false,
			comparePurchaseTableActive: false,
		}
	},
	methods: {
		formatToEuro(val = 0): string {
      if(val) {
        return val.toLocaleString(ValidLocales.GERMANY, {
          style: 'currency',
          currency: 'EUR',
          minimumFractionDigits: 2
        });
      } else {
        return '0,00 €';
      }
		},
		formatToPercent(val: number): string {
			if(val)
				return val.toFixed(0) + "%";
			else
				return '0 %';
		},
		myFilter(): void {
			this.leasingTableActive = !this.leasingTableActive;
			this.comparePurchaseTableActive = !this.comparePurchaseTableActive;
		},
	}
})

</script>

<style lang="scss">
@import "@/styles/elements/results.scss";
</style>