<template>
  <label :for="labelFor">
    {{ label }}
    <slot name="after" />
  </label>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'LabelComponent',
  props: {
    label: { type: String, required: true },
    labelFor: { type: String, required: true },
  },
});
</script>