import { createApp } from 'vue'
import { createGtm } from '@gtm-support/vue-gtm';
import { useUrlParams } from "@/composables/useRoutingParameters";
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import { key, store } from './store/store';
import { i18n } from './i18n-setup';

const { isUrlParam } = useUrlParams();

// TODO: Move to env variables, it's not a good idea to have them here in plain text...
let gtm = 'GTM-WQXBN46'; // develop DE
if(process.env.NODE_ENV === 'dev' && isUrlParam('country', 'AT')) gtm = 'GTM-WMPKJX3'; // develop AT
if(process.env.NODE_ENV === 'prod')  gtm = 'GTM-NVP5JDF'; // Production

const app = createApp(App);
app.use(router);
app.use(i18n);

if (isUrlParam('track', 'true')) {
  app.use(createGtm({
    id: gtm,
    defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
    compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
    //nonce: '2726c7f26c', // Will add `nonce` to the script tag
    //enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
    debug: true, // Whether or not display console logs debugs (optional)
    loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
    vueRouter: router, // Pass the router instance to automatically sync with router (optional)
    //ignoredViews: ['homepage'], // Don't trigger events for specified router names (optional)
    //trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
  }));
}
app.use(store, key);
app.mount('#app');