<template>
  <section class="section">
    <div class="container">
      <img :src="image404" class="img-fluid" alt="404 Image" style="margin-top: 2.5em; border-radius: 25px;" />
      <h1 style="text-align: center; font-family: Roboto Slab,sans-serif; margin-top: 1em;">Oh! Hier ist wohl die Kette abgesprungen....!</h1>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'PageNotFound',
    data() {
        return {
            image404: '/img/404.png'
        }
    },
});

</script>
