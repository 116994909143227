import {EMPLOYMENT_ROLE_ENUM, IEmployer} from '@/models/IEmployer';
import {State} from '@/store/store';

export function insuranceMapping(state: State, employer: IEmployer): void {

        // Premium
        // Premium is always included, except for some very old customers. The only decision is who pays for that.
        state.formValues.comfort = employer.comfort;
        state.formDefaults.set("comfort", {
            disabled: true
        });


        state.formValues.comfortPaidBy = employer.comfortPaidBy === EMPLOYMENT_ROLE_ENUM.EMPLOYER ? 1 : 0 ;
        state.formDefaults.set("comfortPaidBy", {
            disabled: (employer.comfortPaidBy !== undefined && employer.comfortPaidBy !== null)
        });

        // PremiumPlus
        state.formValues.wearAndTear = employer.wearAndTear;
        state.formDefaults.set("wearAndTear", {
            disabled: employer.wearAndTearNotAllowed || employer.wearAndTear
        });
        state.formValues.wearAndTearPaidBy = employer.wearAndTearPaidBy === EMPLOYMENT_ROLE_ENUM.EMPLOYER ? 1 : 0 ;
        state.formDefaults.set("wearAndTearPaidBy", {
            disabled: employer.wearAndTearPaidBy !== undefined && employer.wearAndTearPaidBy !== null
        });
        
        // Inspection
        state.formValues.inspection = employer.inspection;
        state.formDefaults.set("inspection", {
            disabled: employer.inspectionNotAllowed || employer.inspection
        });      
        state.formValues.inspectionPaidBy = employer.inspectionPaidBy === EMPLOYMENT_ROLE_ENUM.EMPLOYER ? 1 : 0 ;
        state.formDefaults.set("inspectionPaidBy", {
            disabled: employer.inspectionPaidBy !== undefined && employer.inspectionPaidBy !== null
        }); 

}
