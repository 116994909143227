<template>
  <fieldset :class="'form-check form-switch form-switch-simple field-is-' + type" :disabled="isDisabled(fieldId)">
    <input :id="fieldId" v-model="value" type="checkbox" class="form-check-input" :name="fieldId" />
	<label :for="fieldId" v-if="value" :title="statusOn" class="simple-form-check-label">&nbsp;{{ statusOn }}</label>
	<label :for="fieldId" v-else :title="statusOff" class="simple-form-check-label">&nbsp;{{ statusOff }}</label>
  </fieldset>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { FormPayload } from '@/store/store';
import { ComponentMixins } from './component-mixins';
import emitter from "@/services/emitter";

export default defineComponent({
	name: 'SimpleToggleComponent',
	mixins: [ComponentMixins],
	props: {
		modelValue: {type: Boolean},
		type: { type: String, default: 'simple-toogle' },
		fieldId: { type: String, required: true },
		statusOn: { type: String, required: true },
		statusOff: { type: String, required: true }
	},
	emits: ['update:modelValue'],
	data() {
		return {
			id: Math.ceil(Math.random() * 1000000),
		}
	},
	computed: {
		value: {
			get(): boolean {
				return this.$store.state.formValues[this.fieldId];
			},
			set(value: boolean) {

				this.$store.state.formValues[this.fieldId] = value;
				this.$emit('update:modelValue', value);
				emitter.emit("refreshStore");
			}
		}
	},
	watch: {
		value() {

			this.$store.commit('setFormValue', {key: this.fieldId, value: this.value} as FormPayload);
		}
	},
	created() {
		this.value = this.$store.state.formValues[this.fieldId];
	}
})
</script>

<style lang="scss">
@import "@/styles/elements/form/form-switch-simple.scss";
</style>