<template>

  <template v-if="showField">

    <SelectComponent
      v-if="fieldData['type'] === 'select'"
      :options="fieldData['options']"
      :label="fieldData['label'][$i18n.locale]"
      :field-id="fieldData['id']"    
      :tooltip="fieldData['tooltip']"
      :type="fieldData['type']"
      :default-val="fieldData['defaultVal']"
      :required="fieldData['required']"
      :isExpanded="fieldData['isExpanded']"
    />

    <InputToggleComponent
      v-if="fieldData['type'] === 'toggle'"
      v-model="toggleValue"
      input-name="radioName123"
      :status-on="fieldData['statusOn']"
      :status-off="fieldData['statusOff']"
      :label="fieldData['label'][$i18n.locale]"
      :field-id="fieldData['id']"
      :tooltip="fieldData['tooltip']"
      :type="fieldData['type']"
      :isExpanded="fieldData['isExpanded']"
    />

    <InputRangeComponent
      v-if="fieldData['type'] === 'range'"
      :default-value="fieldData['defaultValue']"
      :min="fieldData['min']"
      :max="fieldData['max']"
      :label="fieldData['label'][$i18n.locale]"
      :field-id="fieldData['id']"
      :tooltip="fieldData['tooltip']"
      :step="fieldData['step']"
      :type="fieldData['type']"
      :format="fieldData['format']"
      :isExpanded="fieldData['isExpanded']"
      :style="fieldData['style']"
    />

    <InputComponent
      v-if="fieldData['type'] === 'text'"
      :placeholder="fieldData['label']"
      :label="fieldData['label'][$i18n.locale]"
      :field-id="fieldData['id']"
      :tooltip="fieldData['tooltip']"
      :type="fieldData['type']"
      :isExpanded="fieldData['isExpanded']"
    />

    <NumberComponent
      v-if="fieldData['type'] === 'number'"
      :label="fieldData['label'][$i18n.locale]"
      :field-id="fieldData['id']"
      :tooltip="fieldData['tooltip']"
      :type="fieldData['type']"
      :isExpanded="fieldData['isExpanded']"
    />

    <template v-if="['toggle', 'select'].includes(fieldData['type']) && fieldData['subfields']">
      <template
        v-for="(subfield) in fieldData['subfields']"
        :key="subfield.id"
      >

        <InputRangeComponent
          v-if="(subfield['type'] === 'range'  && subfield['dispcon'] === toggleValue) || (subfield['type'] === 'range' && subfield['dispcon'] === $store.state.formValues[fieldData['id']])"
          :default-value="subfield['defaultValue']"                
          :min="subfield['min']"
          :max="subfield['max']"
          :label="subfield['label'][$i18n.locale]"
          :field-id="subfield['id']"
          :tooltip="subfield['tooltip']"
          :step="subfield['step']"
          :type="subfield['type']"
          :format="subfield['format']"
          style="margin-left: 1.5em;"
          :isExpanded="subfield['isExpanded']"
        />

        <InputToggleComponent
          v-if="subfield['type'] === 'toggle' && subfield['dispcon'] === toggleValue"
          v-model="toggleValueSubfield"
          input-name="radioName123"
          :status-on="subfield['statusOn']"
          :status-off="subfield['statusOff']"
          :label="subfield['label'][$i18n.locale]"
          :field-id="subfield['id']"
          :tooltip="subfield['tooltip']"
          :type="subfield['type']"
          style="margin-left: 1.5em;"
          :isExpanded="subfield['isExpanded']"
        />
      </template>
    </template>
  </template>

</template>

<script lang="ts">
import { defineComponent } from 'vue'
import SelectComponent from '@/components/form/SelectComponent.vue'
import InputToggleComponent from '@/components/form/InputToggleComponent.vue'
import InputRangeComponent from '@/components/form/InputRangeComponent.vue'
import InputComponent from '@/components/form/InputComponent.vue'
import NumberComponent from '@/components/form/NumberComponent.vue'

export default defineComponent({
    name: 'FieldsComponent',
    components: {
        SelectComponent,
        InputToggleComponent,
        InputRangeComponent,
        InputComponent,
        NumberComponent
    },
    props: { fieldData: { type: Object, required: true } },
    data() {
        return {
            toggleValue: true,
            toggleValueSubfield: false,
            showField: this.checkFieldCondition()
        }
    },
    methods: {
      checkFieldCondition() {
        if ( this.fieldData['countryCon'] ) {          
          return this.fieldData['countryCon'] === this.$store.state.appData.country;
        } else {    
          return true;
        }
      }
    }
})
</script>

<style lang="scss">
@import "@/styles/elements/form/_all.scss";
</style>