import { store } from "@/store/store";

export const ComponentMixins = {
    methods: {
        isDisabled(fieldId: string): boolean {
            if(fieldId !== null) {
                const field = store.state.formDefaults.get(fieldId);
                if(field !== undefined && field.disabled !== undefined)
                    return store.state.formDefaults.get(fieldId).disabled;
            }
            return false;
        }
    } 
}