<template>
  <fieldset>
    <button type="submit" class="btn btn-primary">
      {{ text }}
    </button>
  </fieldset>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SubmitComponent',
  props: {
    text: { type: String, required: true },
  },
});
</script>

<style lang="scss">
@import "@/styles/elements/form/_all.scss";
</style>