<template>
  <section class="section is-large">
    <div class="container">
      <p>
        <template
          v-for="link in links"
          :key="link.link"
        >
          <h2>{{ link['name'] }} = {{ link['link'] }}</h2>
          <iframe
            :src="link['link']"
            class="iframe"
          />
          <hr>
        </template>
      </p>

      <TextImage :text="''" />
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import TextImage from '@/components/TextImageComponent.vue'

export default defineComponent({
	name: 'HomeView',
	components: {
		TextImage
	},
	data() {
		return {
			links: [
				{
					"link": "calculator",
					"name": "ohne Parameter"
				},
				{
					"link": "calculator?code=X",
					"name": "mit Company Code"
				},
				{
					"link": "calculator?code=x",
					"name": "mit falschem Company Code"
				},
				{
					"link": "calculator?lang=en-US",
					"name": "mit Language Parameter"
				},
				{
					"link": "calculator?lang=en-GB",
					"name": "mit falschem Language Parameter"
				},
				{
					"link": "calculator?form=official",
					"name": "mit Form Parameter"
				},
				{
					"link": "calculator?form=selfemployed",
					"name": "mit Form Parameter"
				},
				{
					"link": "calculator?form=beamt",
					"name": "mit falschem Form Parameter"
				},
				{
					"link": "calculator?modal=0",
					"name": "ohne Modal - ohne Möglichkeit einen Code einzutragen"
				}
			]
		}
	}
})

</script>
