import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = ["id", "name", "required"]
const _hoisted_3 = ["data-label", "value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LabelComponent = _resolveComponent("LabelComponent")!

  return (_ctx.validateMode())
    ? (_openBlock(), _createElementBlock("fieldset", {
        key: 0,
        ref: _ctx.fieldId,
        class: _normalizeClass('form-floating mt-3 field-is-'+_ctx.type),
        disabled: _ctx.isDisabled(_ctx.fieldId)
      }, [
        _withDirectives(_createElementVNode("select", {
          id: _ctx.fieldId,
          ref: "select",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selected) = $event)),
          class: _normalizeClass('form-select '+_ctx.requiredClass),
          name: _ctx.fieldId,
          required: _ctx.required
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (item) => {
            return (_openBlock(), _createElementBlock("option", {
              key: item.value,
              "data-label": item.label,
              value: item.value
            }, _toDisplayString(item[_ctx.$i18n.locale]), 9 /* TEXT, PROPS */, _hoisted_3))
          }), 128 /* KEYED_FRAGMENT */))
        ], 10 /* CLASS, PROPS */, _hoisted_2), [
          [_vModelSelect, _ctx.selected]
        ]),
        _createVNode(_component_LabelComponent, {
          label: _ctx.label,
          "label-for": _ctx.fieldId
        }, null, 8 /* PROPS */, ["label", "label-for"])
      ], 10 /* CLASS, PROPS */, _hoisted_1))
    : _createCommentVNode("v-if", true)
}