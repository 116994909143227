import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "btn-toolbar border-top pt-3 justify-content-between",
  role: "group",
  "aria-label": "Formular Navigation"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonComponent = _resolveComponent("ButtonComponent")!
  const _component_IconComponent = _resolveComponent("IconComponent")!
  const _component_Submit = _resolveComponent("Submit")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.index > 0)
      ? (_openBlock(), _createBlock(_component_ButtonComponent, {
          key: 0,
          text: _ctx.$t('message.prev'),
          class: "btn-light",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeStepTo(_ctx.index - 1))),
          "data-testid": "back-button"
        }, null, 8 /* PROPS */, ["text"]))
      : _createCommentVNode("v-if", true),
    _createVNode(_component_ButtonComponent, {
      text: _ctx.$t('message.reset'),
      class: "text-danger fs-6",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.resetStorage())),
      "data-testid": "reset-button"
    }, {
      before: _withCtx(() => [
        _createVNode(_component_IconComponent, {
          icon: "bin",
          size: "6 mb-2"
        })
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["text"]),
    _createCommentVNode(" <span v-if=\"index === 0\" /> "),
    (_ctx.index<_ctx.totalSteps)
      ? (_openBlock(), _createBlock(_component_ButtonComponent, {
          key: 1,
          text: _ctx.$t('message.next'),
          class: "btn-primary",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.changeStepTo(_ctx.index + 1))),
          "data-testid": "continue-button"
        }, null, 8 /* PROPS */, ["text"]))
      : _createCommentVNode("v-if", true),
    (_ctx.index === _ctx.totalSteps)
      ? (_openBlock(), _createBlock(_component_Submit, {
          key: 2,
          text: _ctx.$t('message.submit'),
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.submitForm())),
          "data-testid": "submit-button"
        }, null, 8 /* PROPS */, ["text"]))
      : _createCommentVNode("v-if", true)
  ]))
}