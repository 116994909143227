import { IEmployer } from '@/models/IEmployer';
import { State } from '@/store/store';

/**
 * The conditions for the employer are fixed in the RLV (Rahmenleasingvertrag / DLV = Dienstleistungsleasingvertrag)
 * These are valid for all employees and all their contracts and cannot be changed by the employee, so it makes no sense
 * to make them configurable in the calculator.
 * @param state
 * @param employer
 */

export function employerConditionMapping(state: State, employer: IEmployer): void {

  // Leasingtime
  if (employer.leasingTime) {
    state.formDefaults.set('leasingTime', {
      disabled: true,
    })
    state.formValues.leasingTime = employer.leasingTime;
  } else {
    state.formDefaults.set('leasingTime', {
      disabled: false,
    })
  }

  // Tax deduction
  if (employer.employerTaxDeduction !== undefined) {
    state.formDefaults.set('employerTaxDeduction', {
      disabled: true,
    })
    state.formValues.employerTaxDeduction = employer?.employerTaxDeduction;
  }

  if(employer.updatedCalculation2023 !== undefined && employer.updatedCalculation2023 !== null) {
    state.formDefaults.set('updatedCalculation2023', {
      disabled: true,
    })
    state.formValues.updatedCalculation2023 = employer?.updatedCalculation2023;
  }

  // Employer takes over the rates completely (employer choice, not modifiable)
  const employerTakesOverRates = employer.leasingShareEmployerPercentage !== undefined && employer.leasingShareEmployerPercentage === 100;
  if (employerTakesOverRates) {
    state.formValues.leasingShareEmployerPercentage = 100;

    state.formDefaults.set('leasingShareEmployer', {
        disabled: true,
        })

    state.formDefaults.set('leasingShareEmployerPercentage', {
      disabled: true,
    })

  }


  // Grant Amount Euro
  const employerHasGrantAmount = employer.leasingShareEmployer !== undefined
    && employer.leasingShareEmployer !== null
    && employer.leasingShareEmployerPercentage !== undefined
    && employer.leasingShareEmployerPercentage !== null;

  if (employerHasGrantAmount) {
    state.formDefaults.set("leasingShareEmployer", {
      disabled: true
    });

    state.formDefaults.set("leasingShareEmployerPercentage", {
      disabled: true
    });
  }

  if (employer.leasingShareEmployerPercentage !== undefined && employer.leasingShareEmployerPercentage !== null) {
    state.formValues.leasingShareEmployerPercentage = employer.leasingShareEmployerPercentage;
  }

  if (employer.leasingShareEmployer !== undefined && employer.leasingShareEmployer !== null) {
    state.formValues.leasingShareEmployer = employer.leasingShareEmployer;
  }
}

