import { LeasingTime } from "@/models/IEmployer";

export enum HEALTHCARE_TYPE {
    PUBLIC = 'PUBLIC',
    PRIVATE_WITH_CONTRIBUTION = 'PRIVATE_WITH_CONTRIBUTION',
    PRIVATE_WITHOUT_CONTRIBUTION = 'PRIVATE_WITHOUT_CONTRIBUTION',
}

export enum TYPE_OF_WORK {
    COLLECTIVE_EMPLOYEE = 1,
    NON_COLLECTIVE_EMPLOYEE = 0,
    UNSET = -1,
}

export interface IFormValues {
    bikeAmount: number; // 1 or 0
    bikePrice: number; // 1 or 0
    bikeType?: BIKE_TYPES | null; // 1 or 0
    bonus: number;
    children: number;
    comfort: boolean;
    comfortPaidBy: number; // 1 = EMPLOYER, 0 = EMPLOYEE
    companyCode?: string;
    distanceToWorkplace?: number | null;
    employeechurchtax: boolean | string;
    employeedistrict: string;
    employerTaxDeduction: boolean;
    extraExpense?: number;
    extraExpenses: number;
    firstBike: boolean;
    formMode: boolean;
    healthcareSurcharge: number;
    healthcareType: HEALTHCARE_TYPE;
    inspection: boolean;
    inspectionPaidBy: number; // 1 = EMPLOYER, 0 = EMPLOYEE
    leasingShareEmployer: number; // TODO ??
    leasingShareEmployerPercentage: number;
    leasingTime: LeasingTime;
    maximumNetCharge: number;
    mode: boolean;
    monthlyIncome: number;
    pensionTax: boolean;
    privateCareInsuranceContribution: number;
    privateHealthInsuranceContribution: number;
    recommendedPurchasePrice: number;
    taxClass: 1 | 2 | 3 | 4 | 5 | 6;
    typeOfWork: TYPE_OF_WORK;
    updatedCalculation2023?: boolean;
    wearAndTear: boolean;
    wearAndTearPaidBy: number; // 1 = EMPLOYER, 0 = EMPLOYEE
}

export interface IFormValuesSelfEmployed {
    bikePrice: number;
    leasingTime: LeasingTime;
    comfort?: boolean;
    wearAndTear: boolean;
    inspection: boolean;
    employerTaxDeduction: boolean;
    maximumNetCharge: number;
    bikeType?: BIKE_TYPES | null;
}


export enum BIKE_TYPES {
    BIKE = 'BIKE',
    PEDELEC = 'PEDELEC',
    S_PEDELEC = 'S-PEDELEC',
    E_BIKE = 'E-BIKE',
}