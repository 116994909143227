import { ComposerTranslation, useI18n } from "vue-i18n";

export interface UsableTranslator {
  t : ComposerTranslation
}

export function useTranslator(): UsableTranslator {

  const { t } = useI18n();

  return {
    t
  }
}
