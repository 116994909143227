<template>
  <section class="section is-large my-3">
    <div class="container-fluid">
      <h1 data-testid="result-title-text">{{ $t("message.resultTableHeadline") }}</h1>
      <div class="row mt-3 quick-overview">
        <div class="col-xs-12 col-md-3 text-right">
              <div class="product-badge hidden-md mb-2">
                <div class="inner-badge">
                  <span class="percent"><span>{{formatToPercent(resultData.savings.percentageSavings)}}</span></span>
                  <span class="text">
                    <span class="bigtext">{{ $t("message.productBadgeBigText") }}</span>
                    <span class="smalltext">{{ $t("message.productBadgeSmallText") }}</span>
                  </span>
                </div>
              </div>
        </div>

        <div class="col-xs-12 col-md-9">
          <table class="table">
            <tr>
              <td>{{ $t("message.quickOverviewTd1") }}</td>
              <td />
              <td
                class="text-end"
                >{{formatToEuro(Math.abs(resultData.monthlyCosts.employeeCosts.monthlyLeasingRate))}}</td>
            </tr>
            <tr>
              <td>{{ $t("message.quickOverviewTd2") }}</td>
              <td />
              <td
                class="text-end"
                >{{formatToEuro(resultData.taxCalculationWithLeasing.monthlySalaryConversionWithInsurance)}}</td>
            </tr>
            <tr>
              <td><strong class="text-primary">{{ $t("message.quickOverviewTd3") }}</strong></td>
              <td />
              <td class="text-end bg-primary text-white">
                <strong>{{formatToEuro(resultData.taxCalculationWithLeasing.monthlySalaryConversion)}}</strong>
              </td>
            </tr>
            <tr>
              <td><strong class="text-primary">{{ $t("message.quickOverviewTd4") }}</strong></td>
              <td />
              <td class="text-end bg-primary text-white">
                <strong>{{formatToEuro(resultData.taxCalculationWithLeasing.netCharge)}}</strong>
              </td>
            </tr>
            <tr>
              <td><strong>{{ $t("message.quickOverviewTd5") }}</strong></td>
              <td class="text-end">
                <strong><span>{{formatToPercent(resultData.savings.percentageSavings)}}</span></strong>
              </td>
              <td class="text-end savings-padding">
                <strong>{{formatToEuro(resultData.savings.totalSavings)}}</strong>
              </td>
            </tr>
          </table>
        </div>

        <div class="col-xs-12">

            <strong
              class="accordion-head collapsed d-flex"
              role="button"
              :class="{ active: leasingTableActive }"
              @click="leasingTableActive = !leasingTableActive"
            >{{ $t("message.table1AccordionHeader") }}<span class="pull-right" /></strong>

            <div
              class="accordion-body"
              :class="{ active: leasingTableActive }"
            >
              <div class="responsive-table">
                <table class="table">
                  <tr>
                    <th>{{ $t("message.tableHead1") }}</th>
                    <th class="text-end">
                      {{ $t("message.tableHead2") }}
                    </th>
                    <th class="text-end">
                      {{ $t("message.tableHead3") }}
                    </th>
                  </tr>
                  <tr>
                    <td>{{$t('message.monthlyIncome')}}</td>
                    <td
                      class="text-end">{{formatToEuro(formData.monthlyIncome)}}</td>
                    <td
                      class="field text-end">{{formatToEuro(formData.monthlyIncome)}}</td>
                  </tr>
                  <tr>
                    <td>{{$t('message.calculatedCostEmployeeLeasingAt')}}</td>
                    <td class="text-end" />
                    <td
                      class="field text-end">{{formatToEuro(resultData.taxCalculationWithLeasing.monthlyRates, true)}}</td>
                  </tr>
                  <tr>
                    <td>{{$t('message.calculatedCostEmployeeInsurancePremium')}}</td>
                    <td class="text-end" />
                    <td
                      class="field text-end">{{formatToEuro(resultData.monthlyCosts.employeeCosts.insuranceComfort)}}</td>
                  </tr>
                  <tr>
                    <td>{{$t('message.calculatedCostEmployeeInsurancePremiumPlus')}}</td>
                    <td class="text-end" />
                    <td
                      class="field text-end">{{formatToEuro(resultData.monthlyCosts.employeeCosts.insuranceWearAndTear)}}</td>
                  </tr>
                  <tr>
                    <td>{{$t('message.calculatedCostEmployeeInsuranceInspection')}}</td>
                    <td class="text-end" />
                    <td
                      class="field text-end">{{formatToEuro(resultData.monthlyCosts.employeeCosts.inspection)}}</td>
                  </tr>
                  <tr>
                    <td>{{$t('message.calculatedCostEmployerLeasing')}}</td>
                    <td class="text-end" />
                    <td
                      class="field text-end">{{formatToEuro(resultData.monthlyCosts.employerCosts.leasingRate)}}</td>
                  </tr>
                  <tr>
                    <td>{{$t('message.calculatedCostEmployerInsurancePremium')}}</td>
                    <td class="text-end" />
                    <td
                      class="field text-end">{{formatToEuro(resultData.monthlyCosts.employerCosts.insuranceComfort)}}</td>
                  </tr>
                  <tr>
                    <td>{{$t('message.calculatedCostEmployerInsurancePremiumPlus')}}</td>
                    <td class="text-end" />
                    <td
                      class="field text-end">{{formatToEuro(resultData.monthlyCosts.employerCosts.insuranceWearAndTear)}}</td>
                  </tr>
                  <tr>
                    <td>{{$t('message.calculatedCostEmployerInsuranceInspection')}}</td>
                    <td class="text-end" />
                    <td
                      class="field text-end"
                      >{{formatToEuro(resultData.monthlyCosts.employerCosts.inspection)}}</td>
                  </tr>
                  <tr class="break">
                    <td>{{$t('message.calculatedGrossIncomeAfterCalculation')}}</td>
                    <td
                      class="text-end">{{formatToEuro(resultData.taxCalculationWithoutLeasing.netIncome)}}</td>
                    <td
                      class="field text-end">{{formatToEuro(resultData.taxCalculationWithLeasing.netIncome)}}</td>
                  </tr>
                  <tr>
                    <td>{{$t('message.sozialversicherungKonstant')}}</td>
                    <td
                      class="text-end">{{formatToEuro(resultData.taxCalculationWithoutLeasing.socialInsuranceContribution)}}</td>
                    <td
                      class="field text-end">{{formatToEuro(resultData.taxCalculationWithLeasing.socialInsuranceContribution)}}
                    </td>
                  </tr>
                  <tr>
                    <td>{{$t('message.calculatedLohnsteuer')}}</td>
                    <td
                      class="text-end">{{formatToEuro(resultData.taxCalculationWithoutLeasing.incomeTax)}}</td>
                    <td
                      class="field text-end">{{formatToEuro(resultData.taxCalculationWithLeasing.incomeTax)}}</td>
                  </tr>

                  <tr class="break">
                    <td>{{$t('message.comparisonNetIncome')}}</td>
                    <td
                      class="text-end">{{formatToEuro(resultData.taxCalculationWithoutLeasing.payoutAmount)}}</td>
                    <td
                      class="field text-end">{{formatToEuro(resultData.taxCalculationWithLeasing.payoutAmount)}}
                    </td>
                  </tr>
 
                  <tr class="break">
                    <td>{{$t('message.calculatedNettoBelastung')}}</td>
                    <td class="text-end" />
                    <td
                      class="field text-end">{{formatToEuro(resultData.taxCalculationWithLeasing.netCharge)}}
                    </td>
                  </tr>
                </table>
              </div>
            </div>

            <strong
              class="accordion-head collapsed d-flex"
              role="button"
              :class="{ active: comparePurchaseTableActive }"
              @click="comparePurchaseTableActive = !comparePurchaseTableActive"
            >{{ $t("message.table2AccordionHeader") }}<span class="pull-right" /></strong>

            <div
              class="accordion-body"
              :class="{ active: comparePurchaseTableActive }"
            >
              <div class="responsive-table">
                <table class="table compare-purchase">
                  <tr>
                    <td>
                      {{ $t('message.totalsTotalLeasingRates') }}
                      <span>{{ formValues.leasingTime }}  {{ $t('message.month') }}</span>
                    </td>
                    <td class="text-end" />
                    <td
                      class="field text-end">{{formatToEuro(resultData.totalCosts.totalLeasingRates)}}</td>
                  </tr>
                  <tr>
                    <td>{{$t('message.calculatedRemainingBikeValue')}}</td>
                    <td
                      class="text-end">{{formatToEuro(resultData.bikeCosts.bikePrice)}}</td>
                    <td
                      class="field text-end">{{formatToEuro(resultData.bikeCosts.remainingBikeValue)}}</td>
                  </tr>
                  <tr>
                    <td>
                      {{ $t('message.totalsTotalCostOfLeasing') }}
                      <span>{{ formValues.leasingTime }} {{ $t('message.month') }}</span>
                    </td>
                    <td
                      class="text-end">{{formatToEuro(resultData.totalCosts.totalCostOfPurchase)}}</td>
                    <td
                      class="field text-end">{{formatToEuro(resultData.totalCosts.totalCostOfLeasing)}}</td>
                  </tr>
                  <tr class="break">
                    <td>{{$t('message.savingsAbsolute')}}</td>
                    <td
                      class="text-end">{{formatToPercent(resultData.savings.percentageSavings)}}</td>
                    <td
                      class="field text-end">{{formatToEuro(resultData.savings.totalSavings)}}</td>
                  </tr>
                </table>
              </div>
            </div>


        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent, PropType, toRaw } from "vue";
import { Api } from '@/services/api-service';
import { ValidLocales } from "@/enums/Application";
import { IFormValues } from "@/store/interfaces/IFormValues";
import { IApiResultAustria } from "@/interfaces/api-result.employee-austria";

export default defineComponent({
	name: 'ResultViewAt',
	props: {
		formData: { type: Object as PropType<IFormValues>, default: null },
		resultData: { type: Object as PropType<IApiResultAustria>, default: null },
	},
	data() {
		return {
			result: toRaw(this.resultData),
			leasingTableActive: false,
			comparePurchaseTableActive: false,
      formValues: this.$store.state.formValues,
		}
	},
	methods: {
		formatToEuro(val = 0, negative = false): string {
      if(val) {
        const value =  val.toLocaleString(ValidLocales.GERMANY, {
          style: 'currency',
          currency: 'EUR',
          minimumFractionDigits: 2
        });

        if (!negative) {
          return value;
        } else {
          return "-" + value.replace("-", "");
        }


      } else {
        return '0,00 €';
      }
		},
		formatToPercent(val: number): string {
			if(val)
				return val.toFixed(0) + "%";
			else
				return '0 %';
		},
		myFilter(): void {
			this.leasingTableActive = !this.leasingTableActive;
			this.comparePurchaseTableActive = !this.comparePurchaseTableActive;
		},
	},
  computed: {
    oldNetIncomeAsNumber() : number {
      return this.resultData.taxCalculationWithLeasing.netIncome + this.result.taxCalculationWithoutLeasing.socialInsuranceContribution + this.result.taxCalculationWithLeasing.incomeTax;
    },
    /**
     * Until 01.01.2023 the Sozialversicherungsbeträge (austrian social insurance taxes) were calculated starting from the
     * gross income without taking leasing into consideration. This lead to a higher tax costs for the employee.
     * This calculation and the following one `oldNetChargeCalculation` are used to show the difference to the new calculation.
     * It will be used by our sales managers to explain the difference to the customer.
     */
    oldNetIncomeCalculation(): string {
      return this.formatToEuro(this.oldNetIncomeAsNumber);
    },
    oldNetChargeCalculation(): string {
      return this.formatToEuro(this.resultData.taxCalculationWithoutLeasing.payoutAmount - this.oldNetIncomeAsNumber);
    }
  }
})

</script>

<style lang="scss">
@import "@/styles/elements/results.scss";

/* The oldCalculationHint class is a hint text under the main text in the table with a smaller font size and light grey color */
.oldCalculationHint {
  font-size: 0.8rem;
  color: #6c757d;
  font-weight: normal;
}
</style>