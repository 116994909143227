import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = { class: "col-md-8" }
const _hoisted_3 = ["id", "min", "max", "step", "name"]
const _hoisted_4 = { class: "col-md-4" }
const _hoisted_5 = ["id", "name", "data-testid"]
const _hoisted_6 = {
  key: 1,
  class: "form-text error-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LabelComponent = _resolveComponent("LabelComponent")!

  return (_ctx.validateMode())
    ? (_openBlock(), _createElementBlock("fieldset", {
        key: 0,
        class: _normalizeClass('row mt-3 field-is-' + _ctx.type),
        disabled: _ctx.isDisabled(_ctx.fieldId)
      }, [
        (_ctx.label)
          ? (_openBlock(), _createBlock(_component_LabelComponent, {
              key: 0,
              class: "form-label",
              label: _ctx.$t(_ctx.label),
              "label-for": 'rangeinput-' + _ctx.id
            }, null, 8 /* PROPS */, ["label", "label-for"]))
          : _createCommentVNode("v-if", true),
        _createElementVNode("div", _hoisted_2, [
          _withDirectives(_createElementVNode("input", {
            id: 'range-' + _ctx.id,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
            type: "range",
            class: "form-range",
            min: _ctx.minValue,
            max: _ctx.maxValue,
            step: _ctx.step,
            name: 'range-' + _ctx.id
          }, null, 8 /* PROPS */, _hoisted_3), [
            [_vModelText, _ctx.value]
          ])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _withDirectives(_createElementVNode("input", {
            id: 'rangeinput-' + _ctx.id,
            ref: "niceValue",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.niceValue) = $event)),
            type: "text",
            class: "form-control",
            tabindex: "0",
            pattern: "^\\d.[\\.|,][0-9]{1,2}$",
            name: 'rangeinput-' + _ctx.id,
            onFocus: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onFocus())),
            onBlur: _cache[3] || (_cache[3] = ($event: any) => (_ctx.updateValue())),
            "data-testid": _ctx.fieldId + '-input'
          }, null, 40 /* PROPS, HYDRATE_EVENTS */, _hoisted_5), [
            [_vModelText, _ctx.niceValue]
          ])
        ]),
        (_ctx.isNumberError)
          ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.$t('message.onlyNumbers')), 1 /* TEXT */))
          : _createCommentVNode("v-if", true)
      ], 10 /* CLASS, PROPS */, _hoisted_1))
    : _createCommentVNode("v-if", true)
}