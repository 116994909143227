<template>
  <ul class="nav nav-pills nav-justified">
    <template v-for="(step,index) in steps" :key="step.id">
      <li v-if="activeStep == parseInt(index)" class="nav-item active">
        <ButtonComponent class="nav-link active secondary border-bottom border-primary py-4" aria-current="page" :text="step.headline[$i18n.locale]">
          <template #before>
            <IconComponent :icon="step.icon" size="4 d-block mb-2" />
          </template>
        </ButtonComponent>
      </li>
      <li v-else class="nav-item">
        <ButtonComponent class="nav-link border-bottom py-4" :text="step.headline[$i18n.locale]" @click="changeStepTo(parseInt(index))">
          <template #before>
            <IconComponent :icon="step.icon" size="4 d-block mb-2" />
          </template>
        </ButtonComponent>
      </li>
    </template>
  </ul>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import IconComponent from '@/components/IconComponent.vue';

export default defineComponent({
  name: 'NavTabsComponent',
  components: {
    ButtonComponent,
    IconComponent,
  },
  props: {
    steps: { type: Object, required: true },
    activeStep: { type: Number, required: true },
  },
  emits: ['clicked'],
  methods: {
    changeStepTo (step: number): void {
      if(this.steps.length -1 !== step) {
        this.$emit('clicked', step);
      }        
    }
  },
})
</script>
