import { State } from '@/store/store';
import { STANDARD_BIKE_PRICES } from "@/enums/CalculatorConstants";

export function mapFormLimitsValues(state: State): void {
    const employer = state.employer;
    if(employer) {

        let maxBikePrice = employer.maxBikePrice ?? STANDARD_BIKE_PRICES.MAX;
        
        if (state.formValues.bikeAmount === 2) {
            maxBikePrice /= 2;
        }    

        // total price
        state.formDefaults.set("bikePrice", {
            minValue: employer.minBikePrice,
            maxValue: maxBikePrice,
        });

        if(state.formValues.bikePrice < employer.minBikePrice) {
            state.formValues.bikePrice = employer.minBikePrice;
            state.formValues.recommendedPurchasePrice = employer.minBikePrice;
        }

        if(state.formValues.bikePrice > employer.maxBikePrice)
            state.formValues.bikePrice = employer.maxBikePrice;

        // totalPriceDivergent
        state.formDefaults.set("recommendedPurchasePrice", {
            minValue: employer.minBikePrice ?? STANDARD_BIKE_PRICES.MIN,
            maxValue: STANDARD_BIKE_PRICES.MAX,
        });
        
        if(state.formValues.recommendedPurchasePrice < employer.minBikePrice)
            state.formValues.recommendedPurchasePrice = employer.minBikePrice;

    }
}